export const USER_MANAGEMENT = 'user_management';
export const EDIT_USER = 'edit_user';
export const DELETE_USER = 'delete_user';
export const BLOCK_USER = 'block_user';
export const DOCUMENT_AUTHORIZATION = 'document_authorization';
export const DELETE_CARD = 'delete_card';
export const ADD_CARD = 'add_card';
export const EDIT_CARD = 'edit_card';
export const CARD_MANAGEMENT = 'card_management';
export const CARD_AUTHORIZATION = 'card_authorization';
export const DELETE_ORDER = 'delete_order';
export const EDIT_ORDER = 'edit_order';
export const TERMINAL_MANAGEMENT = 'terminal_management';
export const DELETE_ADMIN = 'delete_admin';
export const EDIT_ADMIN = 'edit_admin';
export const TERMINAL_ADMIN_MANAGEMENT = 'terminal_admin_management';
export const EDIT_PRIZE = 'edit_prize';
export const ORDER_MANAGEMENT = 'order_management';
export const PRIZE_MANAGEMENT = 'prize_management';
export const ORDER_AUTHORIZATION = 'order_authorization';
export const TRANSACTION_MANAGEMENT = 'transaction_management';
export const GATEWAY_MANAGEMENT = 'gateway_management';
export const EDIT_GATEWAY = 'edit_gateway';
export const DELETE_GATEWAY = 'delete_gateway';
export const DELETE_PAYMENT = 'delete_payment';
export const EDIT_PAYMENT = 'edit_payment';
export const PAYMENT_MANAGEMENT = 'payment_management';
export const DELETE_TICKET = 'delete_ticket';
export const EDIT_TICKET = 'edit_ticket';
export const TICKET_MANAGEMENT = 'ticket_management';
export const DELETE_DRAFT = 'delete_draft';
export const NEW_TICKET = 'new_ticket';
export const  DELETE_SETTING = 'delete_setting';
export const  EDIT_SETTING = 'edit_setting';
export const  SETTING_MANAGEMENT = 'setting_management';
export const  DELETE_SUPPLY_SETTING = 'delete_supply_setting';
export const  EDIT_SUPPLY_SETTING = 'edit_supply-setting';
export const  SUPPLY_SETTING_MANAGEMENT = 'supply_setting_management';
export const  SUPPLIES_MANAGEMENT = 'supplies_management';
export const  SUPPLY_MANAGEMENT = 'supply_management';
export const  AUTHENTICATIONS_MANAGEMENT = 'authentications_management';
export const  DELETE_BLACKLIST = 'delete_blacklist';
export const  BLACKLIST_MANAGEMENT = 'blacklist_management';
export const CURRENCY_MANAGEMENT = 'currency_management';
export const INQUIRY_MANAGEMENT = 'inquiry_management';
export const DRAFT_MANAGEMENT = 'draft_management';
export const EDIT_DRAFT = 'edit_draft';
export const DELETE_LOG = 'delete_log';
export const EDIT_LOG = 'edit_log';
export const LOG_MANAGEMENT = 'log_management';
export const PROFIT_MANAGEMENT = 'profit_management';
export const NOTIFICATION_MANAGEMENT = 'notification_management';
export const AFFILIATE_MANAGEMENT = 'affiliate_management';
export const DELETE_DISCOUNT = 'delete_discount';
export const EDIT_DISCOUNT = 'edit_discount';
export const DISCOUNT_MANAGEMENT = 'affiliate_management';
export const WALLET_MANAGEMENT = 'wallet_management';
export const FAV_ADDRESS_MANAGEMENT = 'fav_address_management';





