import {
    USERS_LIST_FETCHED,
    FETCHING_USERS_LIST,
    REDRAW_USERS_LIST,
    FETCHING_USER_INFO,
    USER_INFO_FETCHED,
    USER_INFO_FAILED,
    SWITCH_USER_INFO_DIALOG,
    SWITCH_USER_EDIT_DIALOG,
    UPDATING_USER_INFO,
    USER_INFO_UPDATE_FAILED,
    USER_INFO_UPDATED,
    DELETING_USER,
    USER_DELETE_FAILED,
    USER_DELETED,
    SWITCH_USER_DOC_DIALOG,
    UPDATE_USERS_FILTER,
    BLOCKING_USER,
    USER_BLOCK_FAILED,
    USER_BLOCKED,
    SENDING_NOTE,
    NOTE_SEND_FAILED,
    NOTE_SENT,
    SWITCH_USER_BLOCK_DIALOG,
    SWITCH_USER_NOTE_DIALOG,
    SWITCH_USER_DELETE_DIALOG,
    SWITCH_ADD_USER_DIALOG,
    ADDING_NEW_USER,
    NEW_USER_ADD_FAILED,
    NEW_USER_ADDED,
    CLEAR_USER_INFO,
    BLOCKING_BALANCE_USER,
    USER_BALANCE_BLOCK_FAILED,
    USER_BALANCE_BLOCKED,
    SWITCH_USER_BLOCK_BALANCE_DIALOG,


} from '../../@jumbo/constants/ActionTypes';
import {STATUS} from "../../@jumbo/constants/Project";

const INIT_STATE = {
    users: [],
    totalUsers: 0,
    loadingUsers: false,
    fetchingUser: false,
    selectedUser: {},
    infoDialogOpened: false,
    editDialogOpened: false,
    docDialogOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    gateway: {},
    authAttempts: [],
    blockingUser: false,
    updatingNote: false,
    blockDialogOpened: false,
    blockBalanceDialogOpened: false,
    noteDialogOpened: false,
    deleteDialogOpened: false,
    addUserDialogOpened: false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_USERS_LIST: {
            return {...state, loadingUsers: true, redraw: false};
        }
        // case USE_GATEWAY: {
        //     return {...state, gatewayUsed: false};
        // }
        case USERS_LIST_FETCHED: {
            return {...state, loadingUsers: false, redraw: false, backToFirstPage: false, users: action.payload.list ?? [], totalUsers: action.payload.totalCount};
        }
        case FETCHING_USER_INFO: {
            return {...state, fetchingUser: true};
        }
        case USER_INFO_FETCHED: {
            const data = action.payload.data;
            data.user.auth_status = {
                email_verified: data.user.email_verified,
                phone_number_verified: data.user.phone_number_verified,
                national_card_verified: data.user.national_card_verified,
                selfie_verified: data.user.selfie_verified,
                blocked: data.user.status === STATUS.REJECTED_BY_ADMIN,
                block_note: data.user.block_note
            };
            return {...state, fetchingUser: false, selectedUser: data.user, authAttempts: data.auth_attempts};
        }
        case USER_INFO_FAILED: {
            return {...state, fetchingUser: false};
        }
        case CLEAR_USER_INFO: {
            return {...state, fetchingUser: false, selectedUser: {}, authAttempts: []};
        }
        case UPDATING_USER_INFO: {
            return {...state, updatingUser: true};
        }
        case USER_INFO_UPDATE_FAILED: {
            return {...state, updatingUser: false};
        }
        case USER_INFO_UPDATED: {
            return {...state, updatingUser: false, editDialogOpened: false, redraw: true};
        }
        case BLOCKING_USER: {
            return {...state, blockingUser: true};
        }
        case USER_BLOCK_FAILED: {
            return {...state, blockingUser: false};
        }
        case USER_BLOCKED: {
            return {...state, blockingUser: false, blockDialogOpened: false, redraw: true};
        }
        case BLOCKING_BALANCE_USER: {
            return {...state, blockingUser: true};
        }
        case USER_BALANCE_BLOCK_FAILED: {
            return {...state, blockingUser: false};
        }
        case USER_BALANCE_BLOCKED: {
            return {...state, blockingUser: false, blockBalanceDialogOpened: false, redraw: true};
        }
        case SENDING_NOTE: {
            return {...state, updatingNote: true};
        }
        case NOTE_SEND_FAILED: {
            return {...state, updatingNote: false};
        }
        case NOTE_SENT: {
            return {...state, updatingNote: false, noteDialogOpened: false, redraw: true};
        }
        case UPDATE_USERS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_USER: {
            return {...state, deletingUser: true};
        }
        case USER_DELETE_FAILED: {
            return {...state, deletingUser: false};
        }
        case USER_DELETED: {
            return {...state, deletingUser: false, redraw: true};
        }
        case ADDING_NEW_USER: {
            return {...state, addingUser: true};
        }
        case NEW_USER_ADD_FAILED: {
            return {...state, addingUser: false};
        }
        case NEW_USER_ADDED: {
            return {...state, addingUser: false, addUserDialogOpened: false, redraw: true, backToFirstPage: true, };
        }

        case SWITCH_USER_INFO_DIALOG: {
            return {...state, infoDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_EDIT_DIALOG: {
            return {...state, editDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_DOC_DIALOG: {
            return {...state, docDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_BLOCK_DIALOG: {
            return {...state, blockDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_BLOCK_BALANCE_DIALOG: {
            return {...state, blockBalanceDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_NOTE_DIALOG: {
            return {...state, noteDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_USER_DELETE_DIALOG: {
            return {...state, deleteDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case SWITCH_ADD_USER_DIALOG: {
            return {...state, addUserDialogOpened: action.payload.state, selectedUser: action.payload.data ?? {}, };
        }
        case REDRAW_USERS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

