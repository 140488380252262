import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import {UPDATE_AUTH_USER, USER_TOKEN_EXPIRE} from "../../@jumbo/constants/ActionTypes";
import {authorizeSocket} from "../../@jumbo/utils/Socket";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const localStorageMiddleware = ({ getState }) => {
  return next => action => {
    const result = next(action);
    const states = getState();
    // if ([ UPDATE_AUTH_USER, USER_DATA, USER_DATA_UPDATE ].includes(result.type)) {
    //   localStorage.setItem('user', JSON.stringify(states.auth.currentUser));
    // }
    if([USER_TOKEN_EXPIRE, UPDATE_AUTH_USER].includes(result.type))
      authorizeSocket(states.auth.token)
    return result;
  };
};

function configureStore(initialState = {}) {
  const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, thunk, localStorageMiddleware]));
  return store;
}
export default configureStore;
export { history };
