import {
    PAYMENTS_LIST_FETCHED,
    FETCHING_PAYMENTS_LIST,
    REDRAW_PAYMENTS_LIST,
    FETCHING_PAYMENT_INFO,
    PAYMENT_INFO_FETCHED,
    PAYMENT_INFO_FAILED,
    SWITCH_PAYMENT_INFO_DIALOG,
    SWITCH_PAYMENT_EDIT_DIALOG,
    UPDATING_PAYMENT_INFO,
    PAYMENT_INFO_UPDATED,
    PAYMENT_INFO_UPDATE_FAILED,
    DELETING_PAYMENT,
    PAYMENT_DELETED,
    PAYMENT_DELETE_FAILED,
    SWITCH_PAYMENT_DOC_DIALOG,
    AUTHORIZING_PAYMENT,
    PAYMENT_AUTHORIZED, PAYMENT_AUTHORIZATION_FAILED,
    UPDATE_PAYMENTS_FILTER


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    payments: [],
    totalPayments: 0,
    loadingPayments: false,
    fetchingPayment: false,
    updatingPayment: false,
    authorizingPayment: false,
    selectedPayment: {},
    paymentPayments: [],
    paymentUser: {},
    stats: {},
    paymentInfoOpened: false,
    paymentEditOpened: false,
    paymentAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    gateway: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_PAYMENTS_LIST: {
            return {...state, loadingPayments: true, redraw: false};
        }
        case PAYMENTS_LIST_FETCHED: {
            return {...state, loadingPayments: false, redraw: false, backToFirstPage: false, payments: action.payload.list ?? [],
                totalPayments: action.payload.totalCount, stats: action.payload.data.stats ?? {}};
        }
        case FETCHING_PAYMENT_INFO: {
            return {...state, fetchingPayment: true};
        }
        case PAYMENT_INFO_FETCHED: {
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_payment_verified: user.national_payment_verified,
                selfie_verified: user.selfie_verified,
            };
            return {...state, fetchingPayment: false, selectedPayment: action.payload.data.payment, paymentPayments: action.payload.data.payments, paymentUser: user};
        }
        case PAYMENT_INFO_FAILED: {
            return {...state, fetchingPayment: false};

        }
        case UPDATING_PAYMENT_INFO: {
            return {...state, updatingPayment: true};
        }
        case PAYMENT_INFO_UPDATE_FAILED: {
            return {...state, updatingPayment: false};
        }
        case PAYMENT_INFO_UPDATED: {
            return {...state, updatingPayment: false, paymentEditOpened: false, redraw: true};
        }
        case AUTHORIZING_PAYMENT: {
            return {...state, authorizingPayment: true};
        }
        case PAYMENT_AUTHORIZATION_FAILED: {
            return {...state, authorizingPayment: false, paymentAuthOpened: false, redraw: true};
        }
        case PAYMENT_AUTHORIZED: {
            return {...state, authorizingPayment: false};
        }
        case UPDATE_PAYMENTS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_PAYMENT: {
            return {...state, deletingPayment: true};
        }
        case PAYMENT_DELETE_FAILED: {
            return {...state, deletingPayment: false};
        }
        case PAYMENT_DELETED: {
            return {...state, deletingPayment: false, redraw: true};
        }

        case SWITCH_PAYMENT_INFO_DIALOG: {
            return {...state, paymentInfoOpened: action.payload.state, selectedPayment: action.payload.data ?? {}, };
        }
        case SWITCH_PAYMENT_EDIT_DIALOG: {
            return {...state, paymentEditOpened: action.payload.state, selectedPayment: action.payload.data ?? {}, };
        }
        case SWITCH_PAYMENT_DOC_DIALOG: {
            return {...state, paymentAuthOpened: action.payload.state, selectedPayment: action.payload.data ?? {}, };
        }
        case REDRAW_PAYMENTS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

