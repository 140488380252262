import {
  GET_USER_TERMINALS,
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  USER_TOKEN_EXPIRE
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  token: localStorage.getItem("token"),
  loadUser: false,
  send_forget_password_email: false,
  terminals: (JSON.parse(localStorage.getItem("terminals")) ?? [])
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case UPDATE_AUTH_USER: {
      console.log('Setting user:', action.payload);
      return {
        ...state,
        authUser: action.payload,
        token: localStorage.getItem("token"),
        loadUser: true,
        terminals: (JSON.parse(localStorage.getItem("terminals")) ?? [])
      };
    }
    // case GET_USER_TERMINALS: {
    //   // console.log('terminals reducer', localStorage.getItem("terminals"));
    //   return {
    //     ...state,
    //     terminals: localStorage.getItem("terminals"),
    //     // token: localStorage.getItem("token"),
    //     loadUser: true,
    //   };
    // }
    case USER_TOKEN_EXPIRE: {
      localStorage.removeItem("token");
      console.log('Removing Auth')
      return {...state, authUser: null, loadUser: false, token: '', terminals: []};
    }
    case UPDATE_LOAD_USER: {
      console.log('Update load user:', action.payload)
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    default:
      return state;
  }
};
