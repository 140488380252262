import {
    TRANSACTIONS_LIST_FETCHED,
    FETCHING_TRANSACTIONS_LIST,
    REDRAW_TRANSACTIONS_LIST,
    TRANSACTIONS_LIST_FAILED,
    FETCHING_TRANSACTION_INFO,
    TRANSACTION_INFO_FETCHED,
    TRANSACTION_INFO_FAILED,
    SWITCH_TRANSACTION_INFO_DIALOG,
    SWITCH_TRANSACTION_DOC_DIALOG,
    UPDATE_TRANSACTIONS_FILTER,
    SWITCH_ADD_WITHDRAW_REQ_DIALOG,
    ADDING_NEW_USER,
    NEW_USER_ADDED, NEW_USER_ADD_FAILED, REQUESTING_NEW_WITHDRAW, NEW_WITHDRAW_REQUESTED, NEW_WITHDRAW_REQUEST_FAILED,
} from "../../@jumbo/constants/ActionTypes";

import {

    TRANSACTION_MANAGEMENT, USER_MANAGEMENT,
} from "../../@jumbo/constants/Units";
import {deleteOne, dispatchAction, fetchList, fetchOne, MULTIPART_HEADER, updateOne} from "../../@jumbo/utils/Api";



export const getTransaction = (id) => {
    return fetchOne({
        url: 'wallet/' + id,
        params: {},
        fetchingAction: FETCHING_TRANSACTION_INFO,
        successAction: TRANSACTION_INFO_FETCHED,
        failAction: TRANSACTION_INFO_FAILED,
        unitName: TRANSACTION_MANAGEMENT,
        showError: true
    })
};

export const newWithdraw = (params) => {
    return dispatchAction({
        url: 'withdraws/new',
        params: params,
        fetchingAction: REQUESTING_NEW_WITHDRAW,
        successAction: NEW_WITHDRAW_REQUESTED,
        failAction: NEW_WITHDRAW_REQUEST_FAILED,
        unitName: TRANSACTION_MANAGEMENT,
        showError: true,

    })
};


export const getTransactionsList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'wallet/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_TRANSACTIONS_LIST,
        successAction: TRANSACTIONS_LIST_FETCHED,
        failAction: TRANSACTIONS_LIST_FAILED,
        unitName: TRANSACTION_MANAGEMENT,
        showError: true
    })

};

export const applyListFilter = (filters, sort) => {
    console.log('applyListFilter');
    return (dispatch) => {
        dispatch({type: UPDATE_TRANSACTIONS_FILTER, payload: {data: {filters: filters, sort: sort}}});
    }
};

export const switchInfoDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_TRANSACTION_INFO_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchAddWithdrawReqDialog = (state, info = {}) => {
    console.log("widthdraw req dialog");
    return (dispatch) => {
        dispatch({type: SWITCH_ADD_WITHDRAW_REQ_DIALOG, payload: {state: state, data: info}});
    }
};





