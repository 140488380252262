import {
    CARDS_LIST_FETCHED,
    FETCHING_CARDS_LIST,
    REDRAW_CARDS_LIST,
    FETCHING_CARD_INFO,
    CARD_INFO_FETCHED,
    CARD_INFO_FAILED,
    SWITCH_CARD_INFO_DIALOG,
    SWITCH_CARD_EDIT_DIALOG,
    UPDATING_CARD_INFO,
    CARD_INFO_UPDATE_FAILED,
    CARD_INFO_UPDATED,
    DELETING_CARD,
    CARD_DELETE_FAILED,
    CARD_DELETED,
    SWITCH_CARD_DOC_DIALOG,
    UPDATE_CARDS_FILTER,
    AUTHORIZING_CARD,
    CARD_AUTHORIZATION_FAILED,
    CARD_AUTHORIZED,
    SWITCH_ADD_CARD_DIALOG,
    ADDING_NEW_CARD, NEW_CARD_ADD_FAILED, NEW_CARD_ADDED,


} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    cards: [],
    totalCards: 0,
    loadingCards: false,
    fetchingCard: false,
    updatingCard: false,
    authorizingCard: false,
    selectedCard: {},
    cardUser: {},
    addingCard: false,
    cardInfoOpened: false,
    cardEditOpened: false,
    cardAuthOpened: false,
    addCardOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    smoothMode: false,
    gateway: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_CARDS_LIST: {
            return {...state, loadingCards: !state.smoothMode, redraw: false};
        }
        // case USE_GATEWAY: {
        //     return {...state, gatewayUsed: false};
        // }
        case CARDS_LIST_FETCHED: {
            return {...state, loadingCards: false, redraw: false, backToFirstPage: false, smoothMode: false, cards: action.payload.list ?? [], totalCards: action.payload.totalCount};
        }
        case FETCHING_CARD_INFO: {
            return {...state, fetchingCard: true};
        }
        case CARD_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_card_verified: user.national_card_verified,
                selfie_verified: user.selfie_verified,
            };
            global.selectedCardID = action.payload.data.card.id;
            return {...state, fetchingCard: false, selectedCard: action.payload.data.card, cardUser: user};
        }
        case CARD_INFO_FAILED: {
            global.selectedCardID = null;
            return {...state, fetchingCard: false};

        }
        case ADDING_NEW_CARD: {
            return {...state, addingCard: true};
        }
        case NEW_CARD_ADD_FAILED: {
            return {...state, addingCard: false};
        }
        case NEW_CARD_ADDED: {
            return {...state, addingCard: false, addCardOpened: false, redraw: true, backToFirstPage: true, };
        }

        case UPDATING_CARD_INFO: {
            return {...state, updatingCard: true};
        }
        case CARD_INFO_UPDATE_FAILED: {
            return {...state, updatingCard: false};
        }
        case CARD_INFO_UPDATED: {
            return {...state, updatingCard: false, cardEditOpened: false, redraw: true};
        }

        case UPDATE_CARDS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_CARD: {
            return {...state, deletingCard: true};
        }
        case CARD_DELETE_FAILED: {
            return {...state, deletingCard: false};
        }
        case CARD_DELETED: {
            return {...state, deletingCard: false, redraw: true};
        }

        case SWITCH_CARD_INFO_DIALOG: {
            global.selectedCardID = null;
            return {...state, cardInfoOpened: action.payload.state, selectedCard: action.payload.data ?? {}, };
        }
        case SWITCH_CARD_EDIT_DIALOG: {
            global.selectedCardID = null;
            return {...state, cardEditOpened: action.payload.state, selectedCard: action.payload.data ?? {}, };
        }
        case SWITCH_ADD_CARD_DIALOG: {
            global.selectedCardID = null;
            return {...state, addCardOpened: action.payload.state, selectedCard: action.payload.data ?? {}, };
        }

        case REDRAW_CARDS_LIST: {
            return {...state, redraw: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        }

        case AUTHORIZING_CARD: {
            return {...state, authorizingCard: true};
        }
        case CARD_AUTHORIZATION_FAILED: {
            return {...state, authorizingCard: false, cardAuthOpened: false, redraw: true};
        }
        case CARD_AUTHORIZED: {
            return {...state, authorizingCard: false, cardAuthOpened: false, redraw: true};
        }
        case SWITCH_CARD_DOC_DIALOG: {
            global.selectedCardID = null;
            return {...state, cardAuthOpened: action.payload.state, selectedCard: action.payload.data ?? {}, };
        }
        default:
            return state;
    }
}

