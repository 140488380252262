import {
    DRAFTS_LIST_FETCHED,
    FETCHING_DRAFTS_LIST,
    REDRAW_DRAFTS_LIST,
    DRAFTS_LIST_FAILED,
    FETCHING_DRAFT_INFO,
    DRAFT_INFO_FETCHED,
    DRAFT_INFO_FAILED,
    SWITCH_DRAFT_INFO_DIALOG,
    SWITCH_DRAFT_EDIT_DIALOG,
    UPDATING_DRAFT_INFO,
    DRAFT_INFO_UPDATED,
    DRAFT_INFO_UPDATE_FAILED,
    DELETING_DRAFT,
    DRAFT_DELETED,
    DRAFT_DELETE_FAILED,
    SWITCH_DRAFT_DOC_DIALOG,
    AUTHORIZING_DRAFT,
    DRAFT_AUTHORIZED,
    DRAFT_AUTHORIZATION_FAILED,
    UPDATE_DRAFTS_FILTER,
    SWITCH_ADD_DRAFT_DIALOG,
    NEW_DRAFT_ADDED,
    DRAFT_COPIED, ADDING_NEW_DRAFT, NEW_DRAFT_ADD_FAILED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    drafts: [],
    totalDrafts: 0,
    loadingDrafts: false,
    fetchingDraft: false,
    updatingDraft: false,
    authorizingDraft: false,
    selectedDraft: {},
    draftPayments: [],
    draftUser: {},
    draftInfoOpened: false,
    draftEditOpened: false,
    draftAuthOpened: false,
    draftUsed: false,
    redraw: false,
    backToFirstPage: false,
    draft: {},
    copiedDraft: '',
    addDraftDialogOpened: false,
    addingNewDraft: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_DRAFTS_LIST: {
            return {...state, loadingDrafts: true, redraw: false};
        }
        case DRAFTS_LIST_FETCHED: {
            return {...state, loadingDrafts: false, redraw: false, backToFirstPage: false, drafts: action.payload.list ?? [], totalDrafts: action.payload.totalCount};
        }
        case FETCHING_DRAFT_INFO: {
            return {...state, fetchingDraft: true};
        }
        case DRAFT_INFO_FETCHED: {

            return {...state, fetchingDraft: false, selectedDraft: action.payload.data.draft};
        }
        case DRAFT_INFO_FAILED: {
            return {...state, fetchingDraft: false};

        }
        case UPDATING_DRAFT_INFO: {
            return {...state, updatingDraft: true};
        }
        case DRAFT_INFO_UPDATE_FAILED: {
            return {...state, updatingDraft: false};
        }
        case DRAFT_INFO_UPDATED: {
            return {...state, updatingDraft: false, draftEditOpened: false, redraw: true};
        }
        case AUTHORIZING_DRAFT: {
            return {...state, authorizingDraft: true};
        }
        case DRAFT_AUTHORIZATION_FAILED: {
            return {...state, authorizingDraft: false, draftAuthOpened: false, redraw: true};
        }
        case DRAFT_AUTHORIZED: {
            return {...state, authorizingDraft: false};
        }
        case UPDATE_DRAFTS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_DRAFT: {
            return {...state, deletingDraft: true};
        }
        case DRAFT_DELETE_FAILED: {
            return {...state, deletingDraft: false};
        }
        case DRAFT_DELETED: {
            return {...state, deletingDraft: false, redraw: true};
        }
        case DRAFT_COPIED: {
            console.log('Draft redcer init...');
            return {...state, copiedDraft: action.payload.content};
        }

        case SWITCH_DRAFT_INFO_DIALOG: {
            return {...state, draftInfoOpened: action.payload.state, selectedDraft: action.payload.data ?? {}, };
        }
        case SWITCH_DRAFT_EDIT_DIALOG: {
            return {...state, draftEditOpened: action.payload.state, selectedDraft: action.payload.data ?? {}, };
        }
        case SWITCH_DRAFT_DOC_DIALOG: {
            return {...state, draftAuthOpened: action.payload.state, selectedDraft: action.payload.data ?? {}, };
        }
        case REDRAW_DRAFTS_LIST: {
            return {...state, redraw: true};
        }
        case SWITCH_ADD_DRAFT_DIALOG: {
            return {...state, addDraftDialogOpened: action.payload.state};
        }
        case NEW_DRAFT_ADDED: {
            return {...state, addDraftDialogOpened: false, redraw: true, backToFirstPage: true, addingNewDraft: false }
        }
         case ADDING_NEW_DRAFT: {
                    return {...state, addingNewDraft: true }
                }
        case NEW_DRAFT_ADD_FAILED: {
            return {...state, addingNewDraft: false, addDraftDialogOpened: false }
        }

        default:
            return state;
    }
}

