import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Auth from './Auth';
import UserReducer from "./UserReducer";
import CardReducer from "./CardReducer";
import OrderReducer from "./OrderReducer";
import TransactionReducer from "./TransactionReducer";
import GatewayReducer from "./GatewayReducer";
import PaymentReducer from "./PaymentReducer";
import TicketReducer from "./TicketReducer";
import SettingReducer from "./SettingReducer";
import SupplySettingReducer from "./SupplySettingReducer";
import Authentication from "./Authentication";
import Blacklist from "./Blacklist";
import Currency from "./Currency";
import InquiryReducer from "./InquiryReducer";
import DraftReducer from "./DraftReducer";
import LogReducer from "./LogReducer";
import ProfitReducer from "./ProfitReducer";
import NoticeReducer from "./NoticeReducer";
import AffiliateReducer from "./AffiliateReducer";
import PrizeReducer from "./PrizeReducer";
import TerminalReducer from "./TerminalReducer";
import Layout from "./Layout"
import DiscountReducer from "./DiscountReducer";
import WalletReducer from "./WalletReducer";
import FavAddressReducer from "./FavAddressReducer";

export default history =>
    combineReducers({
        router: connectRouter(history),
        common: Common,
        taskList: TaskList,
        dashboard: Dashboard,
        mailApp: MailApp,
        chat: Chat,
        auth: Auth,
        contactApp: ContactApp,
        profileApp: ProfileApp,
        wallApp: WallApp,
        user: UserReducer,
        card: CardReducer,
        order: OrderReducer,
        transaction: TransactionReducer,
        gateway: GatewayReducer,
        payment: PaymentReducer,
        ticket: TicketReducer,
        setting: SettingReducer,
        supplySetting: SupplySettingReducer,
        authentication: Authentication,
        blacklist: Blacklist,
        currency: Currency,
        inquiry: InquiryReducer,
        draft: DraftReducer,
            log: LogReducer,
            profit: ProfitReducer,
            notice: NoticeReducer,
            affiliate: AffiliateReducer,
            prize: PrizeReducer,
            terminal: TerminalReducer,
            discount: DiscountReducer,
        layout: Layout,
        wallet: WalletReducer,
        favAddress: FavAddressReducer
    });
