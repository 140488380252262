import {
    ADD_CONNECTION,
    ADD_LABEL, CARD_DELETE_FAILED, CARD_DELETED,
    CLOSING_TICKET,
    COMPOSE_SENT,
    DELETE_LABEL_ITEM, DELETING_CARD, DELETING_TICKET, DRAFT_COPIED,
    FETCHING_TICKET_INFO,
    FETCHING_TICKETS_LIST,
    GET_CONNECTIONS_LIST,
    GET_LABELS_LIST,
    GET_MAIL_COUNTS,
    GET_MAILS_TICKET,
    NEW_TICKET_SENDING,
    NEW_TICKET_SENDING_FAILED,
    NEW_TICKET_SENT,
    NULLIFY_SELECTED_MAIL,
    REMOVE_CONNECTION,
    SET_FILTER_TYPE, SWITCH_BLACKLIST_INFO_DIALOG, SWITCH_DELETE_TICKET_CONFIRM_DIALOG,
    TICKET_CLOSE_FAILED,
    TICKET_CLOSED, TICKET_DELETE_FAILED, TICKET_DELETED,
    TICKET_INFO_FAILED,
    TICKET_INFO_FETCHED,
    TICKET_MESSAGE_SENDING,
    TICKET_MESSAGE_SENDING_FAILED,
    TICKET_MESSAGE_SENT, TICKET_REPLY_SENT,
    TICKETS_LIST_FAILED,
    TICKETS_LIST_FETCHED,
    TOGGLE_SIDEBAR_COLLAPSED,
    UPDATE_FAVORITE_STATUS,
    UPDATE_IMPORTANT_STATUS,
    UPDATE_LABEL_ITEM,
    UPDATE_MAIL_FOLDER,
    UPDATE_MAIL_LABEL,
    UPDATE_READ_STATUS,
    UPDATE_SELECTED_MAIL,
    UPDATE_TICKETS_FILTER,
} from '../../@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'axios';
import {deleteOne, dispatchAction, fetchList, fetchOne, MULTIPART_HEADER, updateOne} from "../../@jumbo/utils/Api";
import {
    DELETE_CARD,
    NEW_TICKET,
    TICKET_MANAGEMENT
} from "../../@jumbo/constants/Units";
import AlarmManager from "../../@jumbo/utils/AlarmManager";
import {STATUS} from "../../@jumbo/constants/Project";


export const deleteTicket = (id) => {
    return deleteOne({
        url: 'tickets/' + id,
        params: {},
        fetchingAction: DELETING_TICKET,
        successAction: TICKET_DELETED,
        failAction: TICKET_DELETE_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true
    })
};
export const switchDeleteTicketConfirmDialog = (state, info = {}) => {
    console.log("action delete", state, info)
    return (dispatch) => {
        dispatch({type: SWITCH_DELETE_TICKET_CONFIRM_DIALOG, payload: {state: state, data: info}});
    }
}

export const getTicketsList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'tickets/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_TICKETS_LIST,
        successAction: TICKETS_LIST_FETCHED,
        failAction: TICKETS_LIST_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true
    })

};

export const applyListFilter = (filters, sort) => {
    return (dispatch) => {
        dispatch({type: UPDATE_TICKETS_FILTER, payload: {data: {filters: filters, sort: sort}}});
    }
};

//For expanding sidebar
export const toggleExpandSidebar = value => {
    return dispatch => {
        dispatch({
            type: TOGGLE_SIDEBAR_COLLAPSED,
            payload: value,
        });
    };
};

//For setting Filtertype
export const setFilterType = filterType => {
    return {
        type: SET_FILTER_TYPE,
        payload: filterType,
    };
};

//for getting unread mail counts
export const getMailCounts = () => {
    return dispatch => {
        axios
            .get('/counter')
            .then(data => {
                if (data.status === 200) {
                    dispatch({ type: GET_MAIL_COUNTS, payload: data.data });
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for getting labels list
export const getLabelsList = () => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .get('/labels')
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: GET_LABELS_LIST, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for adding new label
export const addNewLabel = label => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .post('/labels', { label })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: ADD_LABEL, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//For Deleting Label
export const deleteLabel = labelId => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/labels/delete', { labelId })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: DELETE_LABEL_ITEM, payload: labelId });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//For Editing Label
export const updateLabel = label => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/labels', { label })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_LABEL_ITEM, payload: label });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for getting connections list
export const getConnectionsList = () => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .get('/connections')
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: GET_CONNECTIONS_LIST, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

export const addNewConnection = connection => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .post('/connections', { connection })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: ADD_CONNECTION, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

export const removeConnection = connection => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .delete('/connections', { params: { connection } })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: REMOVE_CONNECTION, payload: connection });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for getting mails list
export const getMailsList = params => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .get('/tickets', { params })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: GET_MAILS_TICKET, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for updating mails folder(through listing)
export const updateMailsFolder = (mailIds, folder) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/mailApp/update-folder', { mailIds, folder })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_MAIL_FOLDER, payload: mailIds });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for updating mails label(through listing)
export const updateMailsLabel = (mailIds, label) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/mailApp/update-label', { mailIds, label })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_MAIL_LABEL, payload: data.data });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for updating mails favorite status(through listing)
export const updateFvrtStatus = (mailIds, status) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/mailApp/update-favorite', { mailIds, status })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_FAVORITE_STATUS, payload: { mailIds, status } });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for updating mails read status(through listing)
export const updateReadStatus = (mailIds, status) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/mailApp/update-read', { mailIds, status })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_READ_STATUS, payload: { mailIds, status } });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for updating mails Important status(through listing)
export const updateImprtntStatus = (mailIds, status) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/mailApp/update-important', { mailIds, status })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_IMPORTANT_STATUS, payload: { mailIds, status } });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for Composing mail
export const composeMail = data => {
    return dispatchAction({
        url: 'tickets/new',
        params: data,
        fetchingAction: NEW_TICKET_SENDING,
        successAction: NEW_TICKET_SENT,
        failAction: NEW_TICKET_SENDING_FAILED,
        unitName: NEW_TICKET,
        showError: true,
        onSuccess: function (data, dispatch) {
        }
    })

};
export const onCloseTicket = (id) => {
    console.log("close action", id)
    return dispatchAction({
        url: 'tickets/close/' + id,
        params: {},
        fetchingAction: CLOSING_TICKET,
        successAction: null,
        failAction: TICKET_CLOSE_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            dispatch({type: TICKET_CLOSED, payload: {ticket_id: id, status: STATUS.REJECTED_BY_ADMIN}})
        },
    })
};

export const onGetSelectedMail = (id) => {
    return fetchOne({
        url: 'tickets/' + id,
        params: {},
        fetchingAction: FETCHING_TICKET_INFO,
        successAction: TICKET_INFO_FETCHED,
        failAction: TICKET_INFO_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true
    })
};
export const onCloseMail = (id) => {
    console.log("close action", id)
    return dispatchAction({
        url: 'tickets/close/' + id,
        params: {},
        fetchingAction: CLOSING_TICKET,
        successAction: null,
        failAction: TICKET_CLOSE_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true
    })
};

//for updating mail through detail page
export const onUpdateMail = mail => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put('/tickets', { mail })
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({ type: UPDATE_SELECTED_MAIL, payload: mail });
                } else {
                    dispatch(fetchError('Something went wrong'));
                }
            })
            .catch(error => {
                dispatch(fetchError('Something went wrong'));
            });
    };
};

//for replying to mail
// export const onReplyToMail = (id, mail) => {
//     return dispatch => {
//         dispatch(fetchStart());
//         axios
//             .put(`/mail/reply`, { id, mail })
//             .then(data => {
//                 if (data.status === 200) {
//                     dispatch(fetchSuccess());
//                     dispatch({ type: UPDATE_SELECTED_MAIL, payload: data.data });
//                 } else {
//                     dispatch(fetchError('Something went wrong'));
//                 }
//             })
//             .catch(error => {
//                 dispatch(fetchError('Something went wrong'));
//             });
//     };
// };
export const onReplyToMail = data => {
    return dispatchAction({
        url: 'tickets/answer',
        params: data,
        fetchingAction: TICKET_MESSAGE_SENDING,
        successAction: TICKET_MESSAGE_SENT,
        failAction: TICKET_MESSAGE_SENDING_FAILED,
        unitName: TICKET_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            AlarmManager.messageSent();
        },
        customHeaders: {
            'content-type': 'multipart/form-data'
        }
    })

};

export const setTicketReplySent = (reply) => {
    return {type: TICKET_REPLY_SENT, payload: reply};
};
export const setComposeSent = (reply) => {
    return {type: COMPOSE_SENT, payload: reply};
};


//for nullifying mail details and showing mail list
export const onNullifyMail = (dispatch) => {
    dispatch({type: NULLIFY_SELECTED_MAIL,});
    dispatch({type: DRAFT_COPIED, payload: {content: ''}});
};





