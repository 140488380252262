import {
    ORDERS_LIST_FETCHED,
    FETCHING_ORDERS_LIST,
    REDRAW_ORDERS_LIST,
    FETCHING_ORDER_INFO,
    ORDER_INFO_FETCHED,
    ORDER_INFO_FAILED,
    SWITCH_ORDER_INFO_DIALOG,
    SWITCH_ORDER_EDIT_DIALOG,
    UPDATING_ORDER_INFO,
    ORDER_INFO_UPDATE_FAILED,
    ORDER_INFO_UPDATED,
    DELETING_ORDER,
    ORDER_DELETE_FAILED,
    ORDER_DELETED,
    SWITCH_ORDER_DOC_DIALOG,
    AUTHORIZING_ORDER,
    ORDER_AUTHORIZATION_FAILED,
    ORDER_AUTHORIZED,
    SWITCH_WITHDRAW_DIALOG,
    PROCESSING_ORDER,
    ORDER_PROCESS_FAILED,
    ORDER_PROCESSED,
    CANCELING_ORDER,
    ORDER_CANCELED,
    ORDER_CANCEL_FAILED,
    LOCKING_ORDER,
    ORDER_LOCKED,
    ORDER_LOCK_FAILED,
    UPDATE_ORDERS_FILTER,
    SWITCH_REQUEST_BUY_DIALOG,
    SWITCH_DEPOSIT_DIALOG,
    SWITCH_NEW_DEPOSIT_DIALOG,
    SUBMITTING_NEW_DEPOSIT,
    NEW_DEPOSIT_SUBMITTED,
    NEW_DEPOSIT_FAILED,
    REQUESTING_NEW_WITHDRAW,
    NEW_WITHDRAW_REQUESTED,
    NEW_WITHDRAW_REQUEST_FAILED,
    SWITCH_ADD_WITHDRAW_REQ_DIALOG,
    SUBMITTING_NEW_BUY,
    NEW_BUY_SUBMITTED,
    NEW_SUBMIT_FAILED,
    SWITCH_REQUEST_SELL_DIALOG,
    SWITCH_BUY_DOCUMENT_DIALOG,
    SWITCH_SELL_DOCUMENT_DIALOG,
    NEW_SELL_SUBMIT_FAILED,
    NEW_SELL_SUBMITTED,
    SUBMITTING_NEW_SELL,
    SWITCH_UNFREEZE_DIALOG, UNFREEZING_ORDER, ORDER_UNFROZEN,


} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    orders: [],
    totalOrders: 0,
    loadingOrders: false,
    fetchingOrder: false,
    updatingOrder: false,
    authorizingOrder: false,
    selectedOrder: {},
    payments: [],
    walletTransfers: [],
    orderUser: {},
    orderInfoOpened: false,
    orderEditOpened: false,
    orderAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    smoothMode: false,
    backToFirstPage: false,
    gateway: {},
    processingOrder: false,
    cancelingOrder: false,
    lockingOrder: false,
    requestBuyDialogOpened: false,
    withdrawDialogOpened: false,
    depositDialogOpened: false,
    newDepositDialogOpened: false,
    requestingNewWithdraw: false,
    addWithdrawReqOpened: false,
    requestSellDialogOpened: false,
    buyDocDialogOpened: false,
    sellDocDialogOpened: false,
    unfreezeDialogOpened: false,
    transferID: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_ORDERS_LIST: {
            return {...state, loadingOrders: !state.smoothMode, redraw: false};
        }
        case ORDERS_LIST_FETCHED: {
            return {...state, loadingOrders: false, redraw: false, smoothMode: false, backToFirstPage: false, orders: action.payload.list ?? [], totalOrders: action.payload.totalCount};
        }
        case FETCHING_ORDER_INFO: {
            return {...state, fetchingOrder: true};
        }
        case ORDER_INFO_FETCHED: {
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_card_verified: user.national_card_verified,
                selfie_verified: user.selfie_verified,
            };
            global.selectedOrderID = action.payload.data.factor.id;
            return {...state, fetchingOrder: false, selectedOrder: action.payload.data.factor, payments: action.payload.data.payments,
                walletTransfers: action.payload.data.wallet_transfers, orderUser: user};
        }
        case ORDER_INFO_FAILED: {
            return {...state, fetchingOrder: false};

        }
        case UPDATING_ORDER_INFO: {
            return {...state, updatingOrder: true};
        }
        case ORDER_INFO_UPDATE_FAILED: {
            return {...state, updatingOrder: false};
        }
        case ORDER_INFO_UPDATED: {
            return {...state, updatingOrder: false, withdrawDialogOpened: false, depositDialogOpened: false,
                buyDocDialogOpened: false, sellDocDialogOpened: false,orderInfoOpened: false};
        }
        case AUTHORIZING_ORDER: {
            return {...state, authorizingOrder: true};
        }
        case ORDER_AUTHORIZATION_FAILED: {
            return {...state, authorizingOrder: false, orderAuthOpened: false, redraw: true};
        }
        case ORDER_AUTHORIZED: {
            return {...state, authorizingOrder: false};
        }
        case PROCESSING_ORDER: {
            return {...state, processingOrder: true};
        }
        case ORDER_PROCESSED: {
            return {...state, processingOrder: false, withdrawDialogOpened: false, depositDialogOpened: false,
                buyDocDialogOpened: false, sellDocDialogOpened: false, redraw: true, backToFirstPage: false};
        }
        case ORDER_PROCESS_FAILED: {
            return {...state, processingOrder: false, };
        }
        case SUBMITTING_NEW_BUY: {
            return {...state, submittingBuy: true};
        }
        case NEW_BUY_SUBMITTED: {
            return {...state, submittingBuy: false, requestBuyDialogOpened: false, redraw: true, backToFirstPage: true};
        }
        case NEW_SUBMIT_FAILED: {
            return {...state, submittingBuy: false, };
        }
        case SUBMITTING_NEW_SELL: {
            return {...state, submittingSell: true};
        }
        case NEW_SELL_SUBMITTED: {
            return {...state, submittingSell: false, requestSellDialogOpened: false, redraw: true, backToFirstPage: true};
        }
        case NEW_SELL_SUBMIT_FAILED: {
            return {...state, submittingSell: false, };
        }
        case CANCELING_ORDER: {
            return {...state, cancelingOrder: true};
        }
        case ORDER_CANCELED: {
            return {...state, cancelingOrder: false, withdrawDialogOpened: false, depositDialogOpened: false,
                buyDocDialogOpened: false, sellDocDialogOpened: false, redraw: true , backToFirstPage: false};
        }
        case ORDER_CANCEL_FAILED: {
            return {...state, cancelingOrder: false, };
        }
        case SUBMITTING_NEW_DEPOSIT: {
            return {...state, submittingNewDeposit: true};
        }
        case NEW_DEPOSIT_SUBMITTED: {
            return {...state, submittingNewDeposit: false, newDepositDialogOpened: false, redraw: true, backToFirstPage: true};
        }
        case NEW_DEPOSIT_FAILED: {
            return {...state, submittingNewDeposit: false, newDepositDialogOpened: true};
        }
        case REQUESTING_NEW_WITHDRAW: {
            return {...state, requestingNewWithdraw: true }
        }
        case NEW_WITHDRAW_REQUESTED: {
            return {...state, requestingNewWithdraw: false,  addWithdrawReqOpened: false, redraw: true, backToFirstPage: true}
        }
        case NEW_WITHDRAW_REQUEST_FAILED: {
            return {...state, requestingNewWithdraw: false}
        }
        case LOCKING_ORDER: {
            return {...state, lockingOrder: true};
        }
        case ORDER_LOCKED: {
            return {...state, lockingOrder: false, redraw: true, backToFirstPage: false};
        }
        case ORDER_LOCK_FAILED: {
            return {...state, lockingOrder: false, };
        }
        case UPDATE_ORDERS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true};
        }
        case DELETING_ORDER: {
            return {...state, deletingOrder: true};
        }
        case ORDER_DELETE_FAILED: {
            return {...state, deletingOrder: false};
        }
        case ORDER_DELETED: {
            return {...state, deletingOrder: false, redraw: true, backToFirstPage: false};
        }

        case SWITCH_ORDER_INFO_DIALOG: {
            global.selectedOrderID = null;
            return {...state, orderInfoOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_ORDER_EDIT_DIALOG: {
            global.selectedOrderID = null;
            return {...state, orderEditOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_ORDER_DOC_DIALOG: {
            global.selectedOrderID = null;
            return {...state, orderAuthOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_WITHDRAW_DIALOG: {
            global.selectedOrderID = null;
            return {...state, withdrawDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_DEPOSIT_DIALOG: {
            global.selectedOrderID = null;
            return {...state, depositDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_NEW_DEPOSIT_DIALOG: {
            return {...state, newDepositDialogOpened: action.payload.state };
        }
        case SWITCH_REQUEST_BUY_DIALOG: {
            global.selectedOrderID = null;
            return {...state, requestBuyDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_REQUEST_SELL_DIALOG: {
            global.selectedOrderID = null;
            return {...state, requestSellDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_BUY_DOCUMENT_DIALOG: {
            global.selectedOrderID = null;
            return {...state, buyDocDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_SELL_DOCUMENT_DIALOG: {
            return {...state, sellDocDialogOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }
        case SWITCH_ADD_WITHDRAW_REQ_DIALOG: {
            return {...state, addWithdrawReqOpened: action.payload.state, selectedTransaction: action.payload.data ?? {}, };
        }
        case SWITCH_UNFREEZE_DIALOG: {
            return {...state, unfreezeDialogOpened: action.payload.state, transferID: action.payload.data ?? {}, };
        }
        case REDRAW_ORDERS_LIST: {
            return {...state, redraw: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        }
        case UNFREEZING_ORDER: {
            return {...state, unfreezingOrder: true}
        }
        case ORDER_UNFROZEN: {
            return {...state, unfreezingOrder: false, redraw: true, unfreezeDialogOpened: false }
        }
        default:
            return state;
    }
}

