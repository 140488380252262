import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const SupplyManagement = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/all_orders`} />
                <Route path={`${requestedUrl}/supplies`} component={lazy(() => import('./Supplies'))} />
                <Route path={`${requestedUrl}/supply_settings`} component={lazy(() => import('./Cards'))} />
                <Route path={`${requestedUrl}/special_supplies`} component={lazy(() => import('./SpecialSupplies'))} />
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default SupplyManagement;
