

export const PATH = {
    ALL_ORDERS_LIST: '/order_manager/all',
    WAITING_ORDERS_LIST: '/order_manager/waiting',

    ALL_TRANSACTIONS_LIST: '/transactions/all',
    DEPOSITS_LIST: '/transactions/deposit',
    WITHDRAWS_LIST: '/transactions/withdraw',

    ALL_AUTHENTICATIONS: '/authentication/all',
    USER_AUTHENTICATIONS: '/authentication/user_info',
    RESIDENTIAL_AUTHENTICATIONS: '/authentication/residential',

    ALL_CREDIT_CARDS: '/credit_card/all',
}