import {
    FETCHING_TRANSACTIONS_LIST,
    TRANSACTIONS_LIST_FETCHED,
    FETCHING_TRANSACTION_INFO,
    TRANSACTION_INFO_FETCHED,
    TRANSACTION_INFO_FAILED,
    UPDATING_TRANSACTION_INFO,
    TRANSACTION_INFO_UPDATE_FAILED,
    TRANSACTION_INFO_UPDATED,
    AUTHORIZING_TRANSACTION,
    TRANSACTION_AUTHORIZATION_FAILED,
    TRANSACTION_AUTHORIZED,
    UPDATE_TRANSACTIONS_FILTER,
    DELETING_TRANSACTION,
    TRANSACTION_DELETE_FAILED,
    TRANSACTION_DELETED,
    SWITCH_TRANSACTION_INFO_DIALOG,
    SWITCH_TRANSACTION_EDIT_DIALOG,
    SWITCH_TRANSACTION_DOC_DIALOG,
    REDRAW_TRANSACTIONS_LIST,


} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    transactions: [],
    totalTransactions: 0,
    loadingTransactions: false,
    fetchingTransaction: false,
    updatingTransaction: false,
    authorizingTransaction: false,
    selectedTransaction: {},
    transactionPayments: [],
    transactionUser: {},
    transactionInfoOpened: false,
    transactionEditOpened: false,
    transactionAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    smoothMode: false,
    gateway: {},
    addWithdrawReqOpened: false,
    requestingNewWithdraw: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_TRANSACTIONS_LIST: {
            return {...state, loadingTransactions: !state.smoothMode, redraw: false};
        }
        case TRANSACTIONS_LIST_FETCHED: {
            return {...state, loadingTransactions: false, smoothMode: false, redraw: false, backToFirstPage: false, transactions: action.payload.list ?? [], totalTransactions: action.payload.totalCount};
        }
        case FETCHING_TRANSACTION_INFO: {
            return {...state, fetchingTransaction: true};
        }
        case TRANSACTION_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_order_verified: user.national_order_verified,
                selfie_verified: user.selfie_verified,
            };
            global.selectedTransID = action.payload.data.transaction.factor.id;
            return {...state, fetchingTransaction: false, selectedTransaction: action.payload.data.transaction, transactionPayments: action.payload.data.payments, transactionUser: user};
        }
        case TRANSACTION_INFO_FAILED: {
            return {...state, fetchingTransaction: false};

        }

        case UPDATING_TRANSACTION_INFO: {
            return {...state, updatingTransaction: true};
        }
        case TRANSACTION_INFO_UPDATE_FAILED: {
            return {...state, updatingTransaction: false};
        }
        case TRANSACTION_INFO_UPDATED: {
            return {...state, updatingTransaction: false, transactionEditOpened: false, redraw: true};
        }
        case AUTHORIZING_TRANSACTION: {
            return {...state, authorizingTransaction: true};
        }
        case TRANSACTION_AUTHORIZATION_FAILED: {
            return {...state, authorizingTransaction: false, transactionAuthOpened: false, redraw: true};
        }
        case TRANSACTION_AUTHORIZED: {
            return {...state, authorizingTransaction: false};
        }
        case UPDATE_TRANSACTIONS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_TRANSACTION: {
            return {...state, deletingTransaction: true};
        }
        case TRANSACTION_DELETE_FAILED: {
            return {...state, deletingTransaction: false};
        }
        case TRANSACTION_DELETED: {
            return {...state, deletingTransaction: false, redraw: true};
        }

        case SWITCH_TRANSACTION_INFO_DIALOG: {
            global.selectedTransID = action.payload.data.transaction.factor.id;
            return {...state, transactionInfoOpened: action.payload.state, selectedTransaction: action.payload.data ?? {}, };
        }
        case SWITCH_TRANSACTION_EDIT_DIALOG: {
            global.selectedTransID = action.payload.data.transaction.factor.id;
            return {...state, transactionEditOpened: action.payload.state, selectedTransaction: action.payload.data ?? {}, };
        }
        case SWITCH_TRANSACTION_DOC_DIALOG: {
            global.selectedTransID = action.payload.data.transaction.factor.id;
            return {...state, transactionAuthOpened: action.payload.state, selectedTransaction: action.payload.data ?? {}, };
        }

        case REDRAW_TRANSACTIONS_LIST: {
            return {...state, redraw: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        }
        default:
            return state;
    }
}

