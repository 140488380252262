import axios from "../../services/auth/jwt/config";
import {FETCH_ERROR, FETCH_SUCCESS, USER_TOKEN_EXPIRE} from "../constants/ActionTypes";
import {fetchError} from "../../redux/actions";
import {updateLoadUser} from "../../redux/actions/Auth";

export const MULTIPART_HEADER = {'content-type': 'multipart/form-data'};

export const fetchList = ({url, params, fetchingAction, successAction, failAction, unitName, showError = true}) => {
    return (dispatch) => {
        dispatch({type: fetchingAction});
        axios.post(url , params, {headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }}).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {}});// Replace with fetchSuccess()
                dispatch({type: successAction, payload: {list: data.records, totalCount: data.count, data: data.data}});
            } else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: unitName}});
                dispatch({type: failAction});
            }
        }).catch(function (error) {
            dispatch(fetchError(error.message));
            if(error.response && error.response.status === 401){
                dispatch({type: USER_TOKEN_EXPIRE});
                dispatch(updateLoadUser(true));
            }else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: unitName}});
                dispatch({type: failAction});
            }
            console.log("Error****:", error);
        });
    }
}

export const fetchOne = ({url, params, fetchingAction, successAction, failAction, unitName, showError = true}) => {
    return (dispatch) => {
        dispatch({type: fetchingAction});
        axios.get(url , {
                params: params
            }
        ).then(({data}) => {
            console.log('Response:', data);
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {}});
                dispatch({type: successAction, payload: {data: data.data}});
            } else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: unitName}});
                dispatch({type: failAction});
            }
        }).catch(function (error) {
            dispatch(fetchError(error.message));
            if(error.response && error.response.status === 401){
                dispatch({type: USER_TOKEN_EXPIRE});
                dispatch(updateLoadUser(true));
            }else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: unitName}});
                dispatch({type: failAction});
            }
            console.log("Error****:", error.message);
        });
    }
}

export const deleteOne = ({url, params, fetchingAction, successAction, failAction, unitName, showError = true}) => {
    return (dispatch) => {
        dispatch({type: fetchingAction});
        axios.delete(url , {
                params: params
            }
        ).then(({data}) => {
            console.log('Response:', data);
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {unitName: data.message}, unit: unitName}});
                dispatch({type: successAction, payload: {data: data.data}});
            } else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: unitName}});
                dispatch({type: failAction});
            }
        }).catch(function (error) {
            dispatch(fetchError(error.message));
            if(error.response && error.response.status === 401){
                dispatch({type: USER_TOKEN_EXPIRE});
                dispatch(updateLoadUser(true));
            }else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: unitName}});
                dispatch({type: failAction});
            }
            console.log("Error****:", error.message);
        });
    }
}

export const updateOne = ({url, params, fetchingAction, successAction, failAction, unitName, showError = true,
                              onSuccess = null, onFailed = null, customHeaders= {}}) => {
    return (dispatch) => {
        dispatch({type: fetchingAction});
        axios.put(url , params, {headers: customHeaders}).then(
            ({data}) => {
                if (data.result) {
                    dispatch({type: FETCH_SUCCESS, payload: {messages: {unitName: data.message}, unit: unitName}});
                    dispatch({type: successAction, payload: {data: data.data}});
                    if(typeof onSuccess === 'function')
                        onSuccess(data, dispatch);
                } else {
                    if(showError)
                        dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: unitName}});
                    dispatch({type: failAction});
                    if(typeof onFailed === 'function')
                        onFailed(data, dispatch);
                }
        }).catch(function (error) {
            dispatch(fetchError(error.message));
            if(error.response && error.response.status === 401){
                dispatch({type: USER_TOKEN_EXPIRE});
                dispatch(updateLoadUser(true));
            }else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: unitName}});
                dispatch({type: failAction});
                if(typeof onFailed === 'function')
                    onFailed({}, dispatch);
            }
            console.log("Error****:", error.message);
        });
    }
}

export const dispatchAction = ({url, params, fetchingAction, successAction, failAction, unitName,
                                   showError = true, onSuccess = null, onFailed = null, customHeaders= {}}) => {
    return (dispatch) => {
        dispatch({type: fetchingAction});
        axios.post(url , params, {headers: customHeaders}
        ).then(({data}) => {
            console.log('Response:', data);
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {unitName: data.message}, unit: unitName}});
                dispatch({type: successAction, payload: {data: data.data}});
                if(typeof onSuccess === 'function')
                    onSuccess(data, dispatch);
            } else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: unitName}});
                dispatch({type: failAction});
                if(typeof onFailed === 'function')
                    onFailed(data, dispatch);
            }
        }).catch(function (error) {
            dispatch(fetchError(error.message));
            if(error.response && error.response.status === 401){
                dispatch({type: USER_TOKEN_EXPIRE});
                dispatch(updateLoadUser(true));
            }else {
                if(showError)
                    dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: unitName}});
                dispatch({type: failAction});
                if(typeof onFailed === 'function')
                    onFailed({}, dispatch);
            }
            console.log("Error****:", error.message);
        });
    }
}


