import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const NoticeManagement = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
         <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/all_orders`} />
                <Route path={`${requestedUrl}/all`} component={lazy(() => import('./Notifications'))} />
                <Route path={`${requestedUrl}/system_notices`} component={lazy(() => import('./SystemNotifications'))} />
                <Route path={`${requestedUrl}/phone_messages`} component={lazy(() => import('./PhoneMessages'))} />
               <Route path={`${requestedUrl}/email_messages`} component={lazy(() => import('./EmailMessages'))} />

             {/*<Route path={`${requestedUrl}/listing`} component={lazy(() => import('./Listing'))} />*/}
                {/*<Route path={`${requestedUrl}/crm`} component={lazy(() => import('./Crm'))} />*/}
                {/*<Route path={`${requestedUrl}/intranet`} component={lazy(() => import('./Intranet'))} />*/}
                {/*<Route path={`${requestedUrl}/eCommerce`} component={lazy(() => import('./ECommerce'))} />*/}
                {/*<Route path={`${requestedUrl}/news`} component={lazy(() => import('./News'))} />*/}
                {/*<Route path={`${requestedUrl}/misc`} component={lazy(() => import('./Misc'))} />*/}
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default NoticeManagement;
