import {WINDOW_WIDTH} from "../../@jumbo/constants/ActionTypes";


const initialLayout = {
    width: window.innerWidth,
};

const layout = (state = initialLayout, action) => {
    switch (action.type) {
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };


        default:
            return state;
    }
};

export default layout;
