import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Blacklist = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/ip`} />
                <Route path={`${requestedUrl}/ip`} component={lazy(() => import('./IP'))} />
                <Route path={`${requestedUrl}/mobile`} component={lazy(() => import('./Mobile'))} />
                <Route path={`${requestedUrl}/email`} component={lazy(() => import('./Email'))} />
                <Route path={`${requestedUrl}/national_id`} component={lazy(() => import('./NationalID'))} />
                <Route path={`${requestedUrl}/wallet`} component={lazy(() => import('./Wallet'))} />
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default Blacklist;
