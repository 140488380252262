import authorization_request from '../assets/sounds/speech/cortana/authorization_request.wav'
import new_card from '../assets/sounds/speech/cortana/new_card.wav'
import new_message from '../assets/sounds/speech/cortana/new_message.wav'
import new_ticket from '../assets/sounds/speech/cortana/new_ticket.wav'
import waiting_order from '../assets/sounds/speech/cortana/waiting_order.wav'
import welcome_back from '../assets/sounds/speech/cortana/welcome_back.wav'
import new_withdraw from '../assets/sounds/speech/cortana/new_withdraw.wav'
import new_message_effect from '../assets/sounds/effects/new_message.wav'
import message_sent_effect from '../assets/sounds/effects/message_sent.wav'
import offline_deposit from '../assets/sounds/speech/cortana/offline_deposit.mp3'

const authorizationRequestSound = new Audio(authorization_request);
const newCardSound = new Audio(new_card);
const newMessageSound = new Audio(new_message);
const newTicketSound = new Audio(new_ticket);
const waitingOrderSound = new Audio(waiting_order);
const welcomeBackSound = new Audio(welcome_back);
const newWithdrawSound = new Audio(new_withdraw);
const offlineDepositSound = new Audio(offline_deposit);
const newMessageEffect = new Audio(new_message_effect);
const messageSentEffect = new Audio(message_sent_effect);


const SOUND_TYPES = {
    SPEECH: 1,
    EFFECT: 2
}

const AudioManager = {
    playAuthorizationRequest : () => {
        authorizationRequestSound.play();
    },
    playNewCard : () => {
        newCardSound.play();
    },
    playNewMessage : (isSelected) => {
        if(isSelected)
            newMessageEffect.play();
        else
            newMessageSound.play();
    },
    playMessageSent : () => {
        messageSentEffect.play();
    },
    playNewTicket : () => {
        newTicketSound.play();
    },
    playWaitingOrder : () => {
        waitingOrderSound.play();
    },
    playWelcomeBack : () => {
        welcomeBackSound.play();
    },
    playNewWithdraw : () => {
        newWithdrawSound.play();
    },
    playOfflineDeposit : () => {
        offlineDepositSound.play();
    },

};
export default AudioManager;
