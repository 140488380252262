import {
    AUTHENTICATIONS_LIST_FETCHED,
    FETCHING_AUTHENTICATIONS_LIST,
    REDRAW_AUTHENTICATIONS_LIST,
    FETCHING_AUTHENTICATION_INFO,
    AUTHENTICATION_INFO_FETCHED,
    AUTHENTICATION_INFO_FAILED,
    SWITCH_AUTHENTICATION_INFO_DIALOG,
    SWITCH_AUTHENTICATION_EDIT_DIALOG,
    UPDATING_AUTHENTICATION_INFO,
    AUTHENTICATION_INFO_UPDATE_FAILED,
    AUTHENTICATION_INFO_UPDATED,
    DELETING_AUTHENTICATION,
    AUTHENTICATION_DELETE_FAILED,
    AUTHENTICATION_DELETED,
    SWITCH_AUTHENTICATION_DOC_DIALOG,
    UPDATE_AUTHENTICATIONS_FILTER,
    AUTHORIZING_AUTHENTICATION,
    AUTHENTICATION_AUTHORIZATION_FAILED,
    AUTHENTICATION_AUTHORIZED,
    AUTHENTICATION_DOC_UPDATED,
    SWITCH_ADD_DOCUMENT_DIALOG,
    ADDING_NEW_USER,
    NEW_USER_ADD_FAILED,
    NEW_USER_ADDED,
    ADDING_NEW_DOCUMENT,
    NEW_DOCUMENT_ADD_FAILED, NEW_DOCUMENT_ADDED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    authentications: [],
    totalAuthentications: 0,
    loadingAuthentications: false,
    fetchingAuthentication: false,
    updatingAuthentication: false,
    authorizingAuthentication: false,
    selectedAuthentication: {},
    authenticationUser: {},
    authenticationInfoOpened: false,
    authenticationEditOpened: false,
    authenticationDocOpened: false,
    addDocumentDialogOpened: false,
    redraw: false,
    backToFirstPage: false,
    smoothMode: false,
    imagesVersion: (+ new Date()),
    addingNewDocument: false,


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_AUTHENTICATIONS_LIST: {
            return {...state, loadingAuthentications: true, smoothMode: !state.smoothMode, redraw: false};
        }
        case AUTHENTICATIONS_LIST_FETCHED: {
            return {...state, loadingAuthentications: false, redraw: false, backToFirstPage: false, smoothMode: false, authentications: action.payload.list ?? [], totalAuthentications: action.payload.totalCount};
        }
        case FETCHING_AUTHENTICATION_INFO: {
            return {...state, fetchingAuthentication: true};
        }
        case ADDING_NEW_DOCUMENT: {
            return {...state, addingNewDocument: true};
        }
        case NEW_DOCUMENT_ADD_FAILED: {
            return {...state, addingNewDocument: false, addDocumentDialogOpened: false};
        }
        case NEW_DOCUMENT_ADDED: {
            return {...state, addingNewDocument: false, addDocumentDialogOpened: false, redraw: true, backToFirstPage: true, };
        }
        case AUTHENTICATION_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_card_verified: user.national_card_verified,
                selfie_verified: user.selfie_verified,
            };
            return {...state, fetchingAuthentication: false, selectedAuthentication: action.payload.data.authentication, authenticationUser: user};
        }
        case AUTHENTICATION_INFO_FAILED: {
            return {...state, fetchingAuthentication: false};

        }
        case UPDATING_AUTHENTICATION_INFO: {
            return {...state, updatingAuthentication: true};
        }
        case AUTHENTICATION_INFO_UPDATE_FAILED: {
            return {...state, updatingAuthentication: false};
        }
        case AUTHENTICATION_INFO_UPDATED: {
            return {...state, updatingAuthentication: false, authenticationEditOpened: false, redraw: true, backToFirstPage: false, };
        }
        case AUTHENTICATION_DOC_UPDATED: {
            return {...state, imagesVersion: ++state.imagesVersion};
        }
        case AUTHORIZING_AUTHENTICATION: {
            return {...state, authorizingAuthentication: true};
        }
        case AUTHENTICATION_AUTHORIZATION_FAILED: {
            return {...state, authorizingAuthentication: false, redraw: true};
        }
        case AUTHENTICATION_AUTHORIZED: {
            return {...state, authorizingAuthentication: false, authenticationDocOpened: false,};
        }
        case UPDATE_AUTHENTICATIONS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_AUTHENTICATION: {
            return {...state, deletingAuthentication: true};
        }
        case AUTHENTICATION_DELETE_FAILED: {
            return {...state, deletingAuthentication: false};
        }
        case AUTHENTICATION_DELETED: {
            return {...state, deletingAuthentication: false, redraw: true};
        }

        case SWITCH_AUTHENTICATION_INFO_DIALOG: {
            return {...state, authenticationInfoOpened: action.payload.state, selectedAuthentication: action.payload.data ?? {}, };
        }
        case SWITCH_AUTHENTICATION_EDIT_DIALOG: {
            return {...state, authenticationEditOpened: action.payload.state, selectedAuthentication: action.payload.data ?? {}, };
        }
        case SWITCH_AUTHENTICATION_DOC_DIALOG: {
            return {...state, authenticationDocOpened: action.payload.state, selectedAuthentication: action.payload.data ?? {}, };
        }
        case SWITCH_ADD_DOCUMENT_DIALOG: {
            return {...state, addDocumentDialogOpened: action.payload.state};
        }
        case REDRAW_AUTHENTICATIONS_LIST: {
            return {...state, redraw: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        }
        default:
            return state;
    }
}

