import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Authentication = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/all`} />
                <Route path={`${requestedUrl}/all`} component={lazy(() => import('./All'))} />
                <Route path={`${requestedUrl}/user_info`} component={lazy(() => import('./UserInfo'))} />
                <Route path={`${requestedUrl}/residential`} component={lazy(() => import('./Residential'))} />
                {/*<Route path={`${requestedUrl}/listing`} component={lazy(() => import('./Listing'))} />*/}
                {/*<Route path={`${requestedUrl}/crm`} component={lazy(() => import('./Crm'))} />*/}
                {/*<Route path={`${requestedUrl}/intranet`} component={lazy(() => import('./Intranet'))} />*/}
                {/*<Route path={`${requestedUrl}/eCommerce`} component={lazy(() => import('./ECommerce'))} />*/}
                {/*<Route path={`${requestedUrl}/news`} component={lazy(() => import('./News'))} />*/}
                {/*<Route path={`${requestedUrl}/misc`} component={lazy(() => import('./Misc'))} />*/}
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default Authentication;
