import socketIOClient from "socket.io-client";
import {
    FETCHING_NETWORKS, NEW_PANEL_USER_NOTICE, NEW_TICKET, NEW_TICKET_MESSAGE, PANEL_USER_NOTICES_FETCHED,
    PRICE_UPDATE_SETTINGS_FETCHED,
    REDRAW_AUTHENTICATIONS_LIST, REDRAW_CARDS_LIST,
    REDRAW_ORDERS_LIST, REDRAW_TICKETS_LIST,
    REDRAW_TRANSACTIONS_LIST, TICKET_CLOSED, TICKET_SEEN, TICKET_TYPING, UPDATE_NETWORK
} from "../constants/ActionTypes";
import {global} from '../../redux/store/global';
import {getOrder} from "../../redux/actions/OrderActions";
import {getTransaction} from "../../redux/actions/Transactions";
import {AES_KEY, STATUS, TRANSFER_TYPES} from "../constants/Project";
import {PATH} from "../constants/Path";
import {getCard} from "../../redux/actions/CardActions";
import {onGetSelectedMail} from "../../redux/actions/TicketApp";
import {
    setAvailCurrencies,
    setBaseCurrencies,
    setCurrencies,
    setSupplies,
    setTranslates
} from "../../redux/actions/CurrencyActions";
import AlarmManager from "./AlarmManager";
const AesEncryption = require('aes-encryption')
const aes = new AesEncryption()
aes.setSecretKey(AES_KEY)

const PRICE_HISTORY_PATHS = [
    PATH.ALL_ORDERS_LIST, PATH.WAITING_ORDERS_LIST,
]
const socketConfig = {
    grabHistory : PRICE_HISTORY_PATHS.includes(window.location.pathname),

}
const socketEndpoint = '/1';
let socket;

export const initializeSocket = (dispatcher) => {
    global.dispatcher = dispatcher;
    socket = socketIOClient(socketEndpoint, {
        query: {token: localStorage.getItem('token')}
    });
    registerListeners(dispatcher);

    socket.on('connect', () => {
        getInitData(socketConfig.grabHistory);
    })
};

export const availableCurrencies = () => {
    socket.emit('availableCurrencies', {}, function (data) {
        global.dispatcher(setAvailCurrencies(data.currencies));
    })
}

export const authorizeSocket = (token) => {
    socket.emit('authorize', {token}, function(data) {
        if(data.authorized)
            getNotices();
    });
}

export const seenUserNotice = (userID, noticeID, seen) => {
    socket.emit('seen_user_notice', {user_id: userID, notice_id: noticeID, seen});
}

export const registerListeners = (dispatcher) => {
    if(socket.hasListeners('prices'))
        return;
    socket.on("prices", data => {
        // if(!global.priceUpdatePeriod)
        //     socket.emit('priceUpdateSettings', {});
        if(data) {
            try{
                const decrypted = aes.decrypt(data);
                // console.log('decrypted price => ', JSON.parse(decrypted));
                dispatcher(setCurrencies(JSON.parse(decrypted)));
            }catch (e) {
                console.log('price error => ', e.message);
            }

        }
    });
    socket.on("base_currencies", data => {
        global.dispatcher(setBaseCurrencies(data.currencies));
    });
    socket.on("supplies", data => {
        if(data)
            dispatcher(setSupplies(data));
    });
    socket.on("new_translate", data => {
        if(data)
            dispatcher(setTranslates(data.currency));
    });
    socket.on("priceUpdateSettings", data => {
        dispatcher({type: PRICE_UPDATE_SETTINGS_FETCHED, payload: data}); // its working dont afraid
    });
    socket.on("networkUpdate", data => {
        dispatcher({type: FETCHING_NETWORKS, payload: {}}); // its working dont afraid
        dispatcher({type: UPDATE_NETWORK, payload: data}); // its working dont afraid
    });
    socket.on("new_transfer", data => {
        if([TRANSFER_TYPES.BUY, TRANSFER_TYPES.SELL].includes(data.type)) {
            // console.log('In buy sell...', window.location.pathname, PATH.ALL_ORDERS_LIST);
            // if(window.location.pathname === PATH.ALL_TRANSACTIONS_LIST)
            //     dispatcher({type: REDRAW_TRANSACTIONS_LIST, payload: {smoothMode: true}});
            if(window.location.pathname === PATH.ALL_ORDERS_LIST)
                dispatcher({type: REDRAW_ORDERS_LIST, payload: {smoothMode: true}});

        }
        else if(data.type === TRANSFER_TYPES.WITHDRAW){
            if(window.location.pathname === PATH.ALL_TRANSACTIONS_LIST)
                dispatcher({type: REDRAW_TRANSACTIONS_LIST, payload: {smoothMode: true}});
            else if(window.location.pathname === PATH.WITHDRAWS_LIST)
                dispatcher({type: REDRAW_ORDERS_LIST, payload: {smoothMode: true}});
            AlarmManager.newWithdraw();
        }
        else if(data.type === TRANSFER_TYPES.DEPOSIT) {
            if(window.location.pathname === PATH.DEPOSITS_LIST)
                dispatcher({type: REDRAW_ORDERS_LIST, payload: {smoothMode: true}});
            if(data.is_offline)
                AlarmManager.offlineDeposit();
        }

    });
    socket.on("new_credit_card", data => {
        // console.log('Received new Credit card:', data);
        if(window.location.pathname === PATH.ALL_CREDIT_CARDS)
            dispatcher({type: REDRAW_CARDS_LIST, payload: {smoothMode: true}});
        AlarmManager.newCard();
    });
    socket.on("update_credit_card", data => {
        // console.log('Received update Credit card:', data);
        if(window.location.pathname === PATH.ALL_CREDIT_CARDS)
            dispatcher({type: REDRAW_CARDS_LIST, payload: {smoothMode: true}});
        if(global.selectedCardID === parseInt(data.card_id)) {
            dispatcher(getCard(data.card_id));
        }
    });
    socket.on("new_auth_request", data => {
        if([PATH.ALL_AUTHENTICATIONS, PATH.USER_AUTHENTICATIONS, PATH.RESIDENTIAL_AUTHENTICATIONS].includes(window.location.pathname))
            dispatcher({type: REDRAW_AUTHENTICATIONS_LIST, payload: {smoothMode: true}});
        AlarmManager.authorizationRequest()
    });
    socket.on("new_user_notice", data => {
        dispatcher({type: NEW_PANEL_USER_NOTICE, payload: data}); // its working dont afraid
    });
    socket.on("update_user_notice", data => {
        getNotices();
    });
    socket.on("new_ticket", data => {
        dispatcher({type: NEW_TICKET, payload: data});
        AlarmManager.newTicket();
    });
    socket.on("new_ticket_message", data => {
        dispatcher({type: NEW_TICKET_MESSAGE, payload: data});
        AlarmManager.newMessage((global.selectedTicket && global.selectedTicket.id === data.ticket_id));

    });
    socket.on("close_ticket", data => {
        dispatcher({type: TICKET_CLOSED, payload: data});

    });
    socket.on("seen_ticket", data => {
        dispatcher({type: TICKET_SEEN, payload: data});
    });
    socket.on("ticket_typing", data => {
        dispatcher({type: TICKET_TYPING, payload: data});
    });
    socket.on("update_ticket", data => {
        dispatcher({type: REDRAW_TICKETS_LIST, payload: {smoothMode: true}}); // its working dont afraid
        if(global.selectedTicket && global.selectedTicket.id === data.ticket_id)
            dispatcher(onGetSelectedMail(data.ticket_id));
    });
    socket.on("transfer_update", data => {
        if(global.selectedOrderID === parseInt(data.transfer_id)) {
            dispatcher(getOrder(data.transfer_id));
            dispatcher({type: REDRAW_ORDERS_LIST, payload: {smoothMode: true}});
        }
        if(global.selectedTransID === parseInt(data.transfer_id)) {
            dispatcher(getTransaction(data.transfer_id));
            dispatcher({type: REDRAW_TRANSACTIONS_LIST, payload: {smoothMode: true}});
        }
        if([TRANSFER_TYPES.BUY, TRANSFER_TYPES.SELL].includes(data.type) && data.changed_status && data.changed_status === STATUS.ADMIN_PROGRESS)
            AlarmManager.waitingOrder();
        switch (window.location.pathname) {
            case PATH.ALL_TRANSACTIONS_LIST:
            case PATH.DEPOSITS_LIST: {
                dispatcher({type: REDRAW_TRANSACTIONS_LIST, payload: {smoothMode: true}});
                break;
            }
            case PATH.ALL_ORDERS_LIST:
            case PATH.WAITING_ORDERS_LIST:
            case PATH.WITHDRAWS_LIST: {
                dispatcher({type: REDRAW_ORDERS_LIST, payload: {smoothMode: true}});
                break;
            }
        }


    });

    socket.on("payment_update", data => {
        switch (window.location.pathname) {
            case PATH.ALL_TRANSACTIONS_LIST:
            case PATH.DEPOSITS_LIST:
            case PATH.WITHDRAWS_LIST: {
                dispatcher({type: REDRAW_TRANSACTIONS_LIST, payload: {smoothMode: true}});
            }
        }
    });
}

const getNotices = () => {
    socket.emit('notices', {}, function (data) {
        // console.log('====> Ack notices data:', data);
        global.dispatcher({type: PANEL_USER_NOTICES_FETCHED, payload: data});
    });
}

export const ticketTyping = (userID, ticketID) => {
    socket.emit('ticket_typing', {user_id: userID, ticket_id: ticketID});
}

export const ticketSeen = (ticketID, userID) => {
    socket.emit('seen_ticket', {ticket_id: ticketID, user_id: userID});
}

const getInitData = (grabHistory) => {
    grabPriceHistory(grabHistory);
    socket.emit('initData', {}, function (data) {
        console.log('====> Ack settings data:', data);
        const decrypted = aes.decrypt(data.prices);
        global.dispatcher({type: PRICE_UPDATE_SETTINGS_FETCHED, payload: data.priceUpdateSettings});
        global.dispatcher(setCurrencies(JSON.parse(decrypted)));
        global.dispatcher(setSupplies(data.supplies));
        global.dispatcher(setTranslates(data.lang.currency));
        global.dispatcher(setBaseCurrencies(data.currencies));
        if(data.authorized)
            getNotices();
    });
}

export const grabPriceHistory = (grab) => {
    if(socketConfig.grabHistory === grab)
        return;
    socketConfig.grabHistory = grab;
    socket.emit('pricesHistory', {active: grab});
}


export default socket;

