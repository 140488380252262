import {

    FETCHING_FAV_ADDRESS_LIST,
    FAV_ADDRESS_LIST_FETCHED,
    UPDATING_FAV_ADDRESS_INFO,
    FETCHING_FAV_ADDRESS_INFO,
    FAV_ADDRESS_INFO_FETCHED,
    FAV_ADDRESS_INFO_FAILED,
    SWITCH_FAV_ADDRESS_EDIT_DIALOG,
    SWITCH_FAV_ADDRESS_INFO_DIALOG,
    REDRAW_FAV_ADDRESS_LIST,
    UPDATE_FAV_ADDRESS_FILTER,
    FAV_ADDRESS_UPDATE_FAILED,
    FAV_ADDRESS_UPDATED,
    ADDING_NEW_FAV_ADDRESS,
    NEW_FAV_ADDRESS_ADD_FAILED,
    NEW_FAV_ADDRESS_ADDED,
    SWITCH_ADD_FAV_ADDRESS_DIALOG,
    SWITCH_DELETE_FAV_ADDRESS_DIALOG,
    DELETING_FAV_ADDRESS, FAV_ADDRESS_DELETE_FAILED, FAV_ADDRESS_DELETED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    redraw: false,
    backToFirstPage: false,
    loadingFavAddresses: false,
    totalFavAddresses: 0,
    favAddresses: [],
    editDialogOpened: false,
    updatingFavAddress: false,
    fetchingFavAddress: false,
    selectedFavAddress: {},
    infoDialogOpened: false,
    addingFavAddress: false,
    deleteDialogOpened: false,
    deletingFavAddress: false,
    addFavAddressDialogOpened: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_FAV_ADDRESS_LIST: {
            return {...state, loadingFavAddresses: true, redraw: false};
        }
        case FAV_ADDRESS_LIST_FETCHED: {
            return {...state, loadingFavAddresses: false, redraw: false, backToFirstPage: false, favAddresses: action.payload.list ?? [], totalFavAddresses: action.payload.totalCount};
        }
        case UPDATING_FAV_ADDRESS_INFO: {
            return {...state, updatingFavAddress: true};
        }
        case FAV_ADDRESS_UPDATE_FAILED: {
            return {...state, updatingFavAddress: false};
        }
        case FAV_ADDRESS_UPDATED: {
            return {...state, updatingFavAddress: false, editDialogOpened: false, redraw: true};
        }
        case FETCHING_FAV_ADDRESS_INFO: {
            return {...state, fetchingFavAddress: true};
        }
        case FAV_ADDRESS_INFO_FETCHED: {
            return {...state, fetchingFavAddress: false, selectedFavAddress: action.payload.data.favoriteAddress};
        }
        case FAV_ADDRESS_INFO_FAILED: {
            return {...state, fetchingFavAddress: false};
        }
        case ADDING_NEW_FAV_ADDRESS: {
            return {...state, addingFavAddress: true};
        }
        case NEW_FAV_ADDRESS_ADD_FAILED: {
            return {...state, addingFavAddress: false};
        }
        case NEW_FAV_ADDRESS_ADDED: {
            return {...state, addingFavAddress: false, addFavAddressDialogOpened: false, redraw: true, backToFirstPage: true, };
        }
        case DELETING_FAV_ADDRESS: {
            return {...state, deletingFavAddress: true};
        }
        case FAV_ADDRESS_DELETE_FAILED: {
            return {...state, deletingFavAddress: false};
        }
        case FAV_ADDRESS_DELETED: {
            return {...state, deletingFavAddress: false, deleteDialogOpened: false, redraw: true};
        }
        case SWITCH_FAV_ADDRESS_EDIT_DIALOG: {
            return {...state, editDialogOpened: action.payload.state, selectedFavAddress: action.payload.data ?? {}, };
        }
        case SWITCH_FAV_ADDRESS_INFO_DIALOG: {
            return {...state, infoDialogOpened: action.payload.state, selectedFavAddress: action.payload.data ?? {}, };
        }
        case SWITCH_ADD_FAV_ADDRESS_DIALOG: {
            return {...state, addFavAddressDialogOpened: action.payload.state, };
        }
        case SWITCH_DELETE_FAV_ADDRESS_DIALOG: {
            return {...state, deleteDialogOpened: action.payload.state, };
        }
        case REDRAW_FAV_ADDRESS_LIST: {
            return {...state, redraw: true};
        }
        case UPDATE_FAV_ADDRESS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        default:
            return state;
    }
}

