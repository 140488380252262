import {
    BLACKLISTS_LIST_FETCHED,
    FETCHING_BLACKLISTS_LIST,
    REDRAW_BLACKLISTS_LIST,
    FETCHING_BLACKLIST_INFO,
    BLACKLIST_INFO_FETCHED,
    BLACKLIST_INFO_FAILED,
    SWITCH_BLACKLIST_INFO_DIALOG,
    SWITCH_BLACKLIST_EDIT_DIALOG,
    UPDATING_BLACKLIST_INFO,
    BLACKLIST_INFO_UPDATE_FAILED,
    BLACKLIST_INFO_UPDATED,
    DELETING_BLACKLIST,
    BLACKLIST_DELETE_FAILED,
    BLACKLIST_DELETED,
    SWITCH_BLACKLIST_DOC_DIALOG,
    UPDATE_BLACKLISTS_FILTER,
    AUTHORIZING_BLACKLIST,
    BLACKLIST_AUTHORIZATION_FAILED, BLACKLIST_AUTHORIZED, ADDING_BLACKLIST, BLACKLIST_ADDED, BLACKLIST_ADD_FAILED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    blacklists: [],
    totalBlacklists: 0,
    loadingBlacklists: false,
    fetchingBlacklist: false,
    updatingBlacklist: false,
    authorizingBlacklist: false,
    selectedBlacklist: {},
    blacklistPayments: [],
    blacklistUser: {},
    blacklistInfoOpened: false,
    blacklistEditOpened: false,
    blacklistAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    gateway: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_BLACKLISTS_LIST: {
            return {...state, loadingBlacklists: true, redraw: false};
        }
        case BLACKLISTS_LIST_FETCHED: {
            return {...state, loadingBlacklists: false, redraw: false, backToFirstPage: false, blacklists: action.payload.list ?? [], totalBlacklists: action.payload.totalCount};
        }
        case UPDATING_BLACKLIST_INFO: {
            return {...state, updatingBlacklist: true};
        }
        case BLACKLIST_INFO_UPDATE_FAILED: {
            return {...state, updatingBlacklist: false};
        }
        case BLACKLIST_INFO_UPDATED: {
            return {...state, updatingBlacklist: false, blacklistEditOpened: false, redraw: true};
        }

        case UPDATE_BLACKLISTS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_BLACKLIST: {
            return {...state, deletingBlacklist: true};
        }
        case BLACKLIST_DELETE_FAILED: {
            return {...state, deletingBlacklist: false};
        }
        case BLACKLIST_DELETED: {
            return {...state, deletingBlacklist: false, redraw: true};
        }

        case ADDING_BLACKLIST: {
            return {...state, addingBlacklist: true};
        }
        case BLACKLIST_ADDED: {
            return {...state, addingBlacklist: false, redraw: true, backToFirstPage: true, };
        }
        case BLACKLIST_ADD_FAILED: {
            return {...state, addingBlacklist: false, redraw: true, backToFirstPage: false, };
        }

        case SWITCH_BLACKLIST_INFO_DIALOG: {
            return {...state, blacklistInfoOpened: action.payload.state, selectedBlacklist: action.payload.data ?? {}, };
        }
        case SWITCH_BLACKLIST_EDIT_DIALOG: {
            return {...state, blacklistEditOpened: action.payload.state, selectedBlacklist: action.payload.data ?? {}, };
        }
        case SWITCH_BLACKLIST_DOC_DIALOG: {
            return {...state, blacklistAuthOpened: action.payload.state, selectedBlacklist: action.payload.data ?? {}, };
        }
        case REDRAW_BLACKLISTS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

