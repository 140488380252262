
import {
    ADD_CONNECTION,
    ADD_LABEL,
    COMPOSE_MAIL,
    DELETE_LABEL_ITEM,
    FETCHING_ORDER_INFO,
    FETCHING_ORDERS_LIST,
    FETCHING_TICKET_INFO,
    FETCHING_TICKETS_LIST,
    GET_CONNECTIONS_LIST,
    GET_LABELS_LIST,
    GET_MAIL_COUNTS,
    GET_MAILS_LIST,
    GET_SELECTED_MAIL,
    NEW_TICKET_SENDING,
    NEW_TICKET_SENDING_FAILED,
    NEW_TICKET_SENT,
    NULLIFY_SELECTED_MAIL,
    ORDER_INFO_FAILED,
    ORDER_INFO_FETCHED,
    ORDERS_LIST_FETCHED,
    REMOVE_CONNECTION,
    REPLY_TO_MAIL,
    SET_FILTER_TYPE,
    TICKET_INFO_FAILED,
    TICKET_INFO_FETCHED,
    TICKET_MESSAGE_SENDING,
    TICKET_MESSAGE_SENDING_FAILED,
    TICKET_MESSAGE_SENT,
    TICKETS_LIST_FETCHED,
    TOGGLE_SIDEBAR_COLLAPSED,
    UPDATE_FAVORITE_STATUS,
    UPDATE_IMPORTANT_STATUS,
    UPDATE_LABEL_ITEM,
    UPDATE_MAIL_FOLDER,
    UPDATE_MAIL_LABEL,
    UPDATE_READ_STATUS,
    UPDATE_SELECTED_MAIL,
    CLOSING_TICKET,
    TICKET_CLOSED,
    TICKET_CLOSE_FAILED,
    REDRAW_ORDERS_LIST,
    REDRAW_TICKETS_LIST,
    FETCHING_GATEWAYS_LIST,
    GATEWAYS_LIST_FETCHED,
    FETCHING_GATEWAY_INFO,
    GATEWAY_INFO_FETCHED,
    GATEWAY_INFO_FAILED,
    UPDATING_GATEWAY_INFO,
    GATEWAY_INFO_UPDATE_FAILED,
    GATEWAY_INFO_UPDATED,
    AUTHORIZING_GATEWAY,
    GATEWAY_AUTHORIZATION_FAILED,
    GATEWAY_AUTHORIZED,
    UPDATE_GATEWAYS_FILTER,
    DELETING_GATEWAY,
    GATEWAY_DELETE_FAILED,
    GATEWAY_DELETED,
    SWITCH_GATEWAY_INFO_DIALOG,
    SWITCH_GATEWAY_EDIT_DIALOG,
    SWITCH_GATEWAY_DOC_DIALOG,
    REDRAW_GATEWAYS_LIST,
    FETCHING_DRAFTS_LIST,
    DRAFTS_LIST_FETCHED,
    FETCHING_DRAFT_INFO,
    DRAFT_INFO_FETCHED,
    DRAFT_INFO_FAILED,
    UPDATING_DRAFT_INFO,
    DRAFT_INFO_UPDATE_FAILED,
    DRAFT_INFO_UPDATED,
    UPDATE_DRAFTS_FILTER,
    DELETING_DRAFT,
    DRAFT_DELETE_FAILED,
    DRAFT_DELETED,
    SWITCH_DRAFT_INFO_DIALOG,
    SWITCH_DRAFT_EDIT_DIALOG,
    REDRAW_DRAFTS_LIST,
    SWITCH_ADD_DRAFT_DIALOG,
    UPDATE_TICKETS_FILTER,
    UPDATE_LOGS_FILTER,
    TICKET_REPLY_SENT,
    COMPOSE_SENT,
    SWITCH_ADD_CARD_DIALOG,
    SWITCH_DELETE_TICKET_CONFIRM_DIALOG,
    DELETING_TICKET,
    TICKET_DELETED, TICKET_DELETE_FAILED, TICKET_SEEN, TICKET_TYPING, NEW_TICKET, NEW_TICKET_MESSAGE
} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";
import {STATUS} from "../../@jumbo/constants/Project";
import {ticketSeen} from "../../@jumbo/utils/Socket";
import {showSuccess} from "../../routes/Components/NotifierContainer";
import {TICKET_MANAGEMENT} from "../../@jumbo/constants/Units";

const INIT_STATE = {
    isSideBarCollapsed: false,
    labelsList: [],
    connectionsList: [],
    loadingTickets: false,
    loadingTicketMassages: false,
    sendingTicketMessages: false,
    sendingNewTicket: false,
    totalTickets: 0,
    tickets: [],
    redraw: false,
    backToFirstPage: false,
    filterType: {
        selectedFolder: 'inbox',
        selectedFilter: '',
        selectedLabel: '',
        searchText: '',
        page: 0,
    },
    selectedMail: null,
    ticketUser: {},
    ticketMessages: [],
    counter: null,
    totalMailCount: null,
    redrawList: false,
    redrawChats: false,
    closingTicket: false,
    ticketClosed: false,
    ReplyTicketSent: false,
    composeSent: false,
    deleteTicketConfirmOpened: false,
    selectedTicket: {},
    deletingTicket: false


};



export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCHING_TICKETS_LIST: {
            return {...state, loadingTickets: !state.smoothMode, redraw: false};
        }
        case TICKETS_LIST_FETCHED: {
            return {...state, loadingTickets: false, smoothMode: false, redraw: false, backToFirstPage: false, tickets: action.payload.list ?? [], totalTickets: action.payload.totalCount};
        }

        case FETCHING_TICKET_INFO: {
            return {...state, fetchingTicketChats: true, selectedMail: {}, ticketUser: {}, ticketMessages: [], };
        }
        case CLOSING_TICKET: {
            return {...state, //closingTicket: true
            };
        }
        case TICKET_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_order_verified: user.national_order_verified,
                selfie_verified: user.selfie_verified,
            };
            global.selectedTicket = action.payload.data.ticket;
            return {...state, fetchingTicketChats: false, selectedMail: action.payload.data.ticket, ticketMessages: action.payload.data.messages,  ticketUser: user, redrawChats: false};
        }
        case TICKET_CLOSED: {
            const tickets = state.tickets.slice();
            const status = action.payload.status;
            let selectedMail = Object.assign({}, state.selectedMail);
            tickets.map((ticket, index) => {
                if(ticket.id === action.payload.ticket_id)
                    tickets[index].status = status;
            })
            if(status === STATUS.REJECTED_BY_ADMIN) {
                if(selectedMail && selectedMail.id === action.payload.ticket_id)
                    selectedMail.status = status;
            } else if(status === STATUS.REJECTED_BY_USER)
                if(selectedMail && selectedMail.id === action.payload.ticket_id) {
                    selectedMail.status = status;
                    showSuccess(TICKET_MANAGEMENT, 'TICKET_CLOSED');
                }
                // selectedMail = {};

            return {...state, selectedMail: selectedMail, tickets: tickets}
        }
        case TICKET_SEEN: {
            const ticketMessages = state.ticketMessages.slice();
            const seen = action.payload.seen;
            let selectedMail = Object.assign({}, state.selectedMail);
            if(selectedMail && selectedMail.id === action.payload.ticket_id) {
                ticketMessages.map((message, index) => {
                    ticketMessages[index].seen = seen;
                });
            }
            return {...state, ticketMessages: ticketMessages}
        }
        case TICKET_TYPING: {
            const now = (new Date().getTime() / 1000);
            let selectedMail = Object.assign({}, state.selectedMail);
            let update = {...state};
            if(selectedMail && selectedMail.id === action.payload.ticket_id && selectedMail.user_id === action.payload.user_id) {
                console.log('Matched mail:', selectedMail);
                selectedMail.isUserTyping = true;
                selectedMail.userTypingTime = now;
                selectedMail.userTypingText = action.payload.message;
                update.selectedMail = selectedMail;
            }
            return update;
        }
        case NEW_TICKET: {
            const tickets = state.tickets.slice();
            const newTicket = action.payload.ticket;
            const newTicketID = action.payload.ticket_id;
            const ticketExists = tickets.some((ticket) => ticket.id == newTicketID);
            if(!ticketExists)
                tickets.unshift(newTicket);
            return {...state, tickets: tickets}
        }
        case NEW_TICKET_MESSAGE: {
            const ticketMessages = state.ticketMessages.slice();
            const tickets = state.tickets.slice();
            const newMessage = action.payload.message;
            const ticketID = action.payload.ticket_id;
            let selectedMail = state.selectedMail;
            if(selectedMail && selectedMail.id === ticketID) {
                ticketMessages.push(newMessage);
                selectedMail.status = STATUS.ADMIN_PROGRESS
                selectedMail.isAdminTyping = false;
                ticketSeen(ticketID, selectedMail.user_id);
            }
            tickets.map((ticket, index) => {
                if(ticket.id == ticketID) {
                    if(!selectedMail || selectedMail.id !== ticketID)
                        tickets[index].unreads++;
                    tickets[index].status = STATUS.ADMIN_PROGRESS;

                }
            })
            return {...state, ticketMessages: ticketMessages, tickets: tickets, selectedMail: selectedMail}
        }
        case TICKET_CLOSE_FAILED: {
            return {...state, closingTicket: false , ticketClosed: false, redrawList: false}
        }

        case TICKET_MESSAGE_SENDING: {
            return {...state, sendingTicketMessage: true};
        }

        case TICKET_MESSAGE_SENT: {
            const ticketMessages = state.ticketMessages.slice();
            const tickets = state.tickets.slice();
            const newMessage = action.payload.data.message;
            const ticketData = action.payload.data.ticket;
            let selectedMail = Object.assign({}, state.selectedMail);
            if(selectedMail && selectedMail.id == ticketData.id) {
                selectedMail = Object.assign(state.selectedMail, ticketData);
                ticketMessages.push(newMessage);
            }
            tickets.map((ticket, index) => {
                if(ticket.id == ticketData.id)
                    tickets[index] = Object.assign(ticket, ticketData);
            })
            return {...state, sendingTicketMessage: false, redrawChats: false, ReplyTicketSent: true,
                selectedMail: selectedMail, ticketMessages: ticketMessages, tickets: tickets};
        }
        case TICKET_REPLY_SENT: {
            return {...state, ReplyTicketSent: action.payload};
        }
        case COMPOSE_SENT: {
            return {...state, composeSent: action.payload};
        }
        case TICKET_MESSAGE_SENDING_FAILED: {
            return {...state, sendingTicketMessage: false};
        }
        case NEW_TICKET_SENDING: {
            return {...state, sendingNewTicket: true};
        }

        case NEW_TICKET_SENT: {
            return {...state, sendingNewTicket: false, redrawList: true, backToFirstPage: true, composeSent: true};
        }
        case NEW_TICKET_SENDING_FAILED: {
            return {...state, sendingTicketMessage: false, backToFirstPage: false, };
        }

        case TICKET_INFO_FAILED: {
            return {...state, fetchingTicketChats: false, redrawChats: false};

        }
        case SWITCH_DELETE_TICKET_CONFIRM_DIALOG: {
            console.log("delete reducer")
            return {...state, deleteTicketConfirmOpened: action.payload.state, selectedTicket: action.payload.data ?? {}, };
        }
        case DELETING_TICKET: {
            return {...state, deletingTicket: true}
        }
        case TICKET_DELETED: {
            return {...state, deletingTicket: false, redraw: true, deleteTicketConfirmOpened: false}
        }
        case TICKET_DELETE_FAILED: {
            return {...state, deletingTicket: false, deleteTicketConfirmOpened: false}
        }




        case TOGGLE_SIDEBAR_COLLAPSED: {
            return {
                ...state,
                isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
            };
        }

        case GET_MAIL_COUNTS: {
            return {
                ...state,
                counter: action.payload,
            };
        }

        case SET_FILTER_TYPE: {
            return {
                ...state,
                filterType: action.payload,
                selectedMail: null,
            };
        }

        case GET_LABELS_LIST: {
            return { ...state, labelsList: action.payload };
        }

        case ADD_LABEL: {
            return {
                ...state,
                labelsList: state.labelsList.concat(action.payload),
            };
        }

        case UPDATE_LABEL_ITEM: {
            return {
                ...state,
                labelsList: state.labelsList.map(item => (item.id === action.payload.id ? action.payload : item)),
            };
        }

        case DELETE_LABEL_ITEM: {
            return {
                ...state,
                labelsList: state.labelsList.filter(item => item.id !== action.payload),
            };
        }

        case GET_CONNECTIONS_LIST: {
            return { ...state, connectionsList: action.payload };
        }

        case ADD_CONNECTION: {
            return {
                ...state,
                connectionsList: state.connectionsList.concat(action.payload),
            };
        }

        case REMOVE_CONNECTION: {
            return {
                ...state,
                connectionsList: state.connectionsList.filter(connection => connection.email !== action.payload.email),
            };
        }

        case GET_MAILS_LIST: {
            return {
                ...state,
                mailsList: action.payload.list,
                totalMailCount: action.payload.total,
                selectedMail: null,
            };
        }

        case UPDATE_MAIL_FOLDER: {
            const updatedList = state.mailsList.filter(mail => !action.payload.includes(mail.id));
            return {
                ...state,
                mailsList: updatedList,
                totalMailCount: state.totalMailCount - action.payload.length,
            };
        }

        case UPDATE_MAIL_LABEL: {
            let mailIds = action.payload.map(mail => mail.id);
            const updatedList = state.mailsList.map(mail => {
                if (mailIds.includes(mail.id)) {
                    return action.payload.find(selectedMail => selectedMail.id === mail.id);
                } else {
                    return mail;
                }
            });
            return {
                ...state,
                mailsList: updatedList,
            };
        }

        case UPDATE_FAVORITE_STATUS: {
            const { mailIds, status } = action.payload;
            const updatedList = state.mailsList.map(mail => {
                if (mailIds.includes(mail.id)) {
                    mail.favorite = status;
                    return mail;
                }
                return mail;
            });
            return {
                ...state,
                mailsList: updatedList,
            };
        }

        case UPDATE_READ_STATUS: {
            const { mailIds, status } = action.payload;
            const updatedList = state.mailsList.map(mail => {
                if (mailIds.includes(mail.id)) {
                    mail.read = status;
                    return mail;
                }
                return mail;
            });
            return {
                ...state,
                mailsList: updatedList,
            };
        }

        case UPDATE_IMPORTANT_STATUS: {
            const { mailIds, status } = action.payload;
            const updatedList = state.mailsList.map(mail => {
                if (mailIds.includes(mail.id)) {
                    mail.important = status;
                    return mail;
                }
                return mail;
            });
            return {
                ...state,
                mailsList: updatedList,
            };
        }

        case COMPOSE_MAIL: {
            let updatedList = state.mailsList;
            let updatedCount = state.totalMailCount;
            if (state.filterType.selectedFolder === 'sent') {
                updatedList = [action.payload, ...updatedList];
                updatedCount = updatedCount + 1;
            }
            return {
                ...state,
                mailsList: updatedList,
                totalMailCount: updatedCount,
            };
        }

        case GET_SELECTED_MAIL: {
            return {
                ...state,
                selectedMail: action.payload,
                mailsList: [],
            };
        }

        case NULLIFY_SELECTED_MAIL: {
            global.selectedTicket = null;
            return {
                ...state,
                selectedMail: null,
            };
        }

        case UPDATE_SELECTED_MAIL: {
            let updatedMail = state.selectedMail.folder === action.payload.folder ? action.payload : null;
            return {
                ...state,
                selectedMail: updatedMail,
            };
        }

        case REPLY_TO_MAIL: {
            return {
                ...state,
                selectedMail: action.payload,
            };
        }

        case REDRAW_TICKETS_LIST: {
            return {...state, redraw: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        }
        case UPDATE_TICKETS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }

        default:
            return state;
    }
};
