import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const NetworkManagement = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/all_orders`} />
                <Route path={`${requestedUrl}/networks`} component={lazy(() => import('./Networks'))} />
                {/*<Route path={`${requestedUrl}/deposits`} component={lazy(() => import('./Gateways/ExchangerDeposits'))} />*/}
                {/*<Route path={`${requestedUrl}/withdraws`} component={lazy(() => import('./Gateways/ExchangerWithdraws'))} />*/}
                {/*<Route path={`${requestedUrl}/listing`} component={lazy(() => import('./Listing'))} />*/}
                {/*<Route path={`${requestedUrl}/crm`} component={lazy(() => import('./Crm'))} />*/}
                {/*<Route path={`${requestedUrl}/intranet`} component={lazy(() => import('./Intranet'))} />*/}
                {/*<Route path={`${requestedUrl}/eCommerce`} component={lazy(() => import('./ECommerce'))} />*/}
                {/*<Route path={`${requestedUrl}/news`} component={lazy(() => import('./News'))} />*/}
                {/*<Route path={`${requestedUrl}/misc`} component={lazy(() => import('./Misc'))} />*/}
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default NetworkManagement;
