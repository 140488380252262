import {
    SETTINGS_LIST_FETCHED,
    FETCHING_SETTINGS_LIST,
    REDRAW_SETTINGS_LIST,
    FETCHING_SETTING_INFO,
    SETTING_INFO_FETCHED,
    SETTING_INFO_FAILED,
    SWITCH_SETTING_INFO_DIALOG,
    SWITCH_SETTING_EDIT_DIALOG,
    UPDATING_SETTING_INFO,
    SETTING_INFO_UPDATE_FAILED,
    SETTING_INFO_UPDATED,
    DELETING_SETTING,
    SETTING_DELETE_FAILED,
    SETTING_DELETED,
    SWITCH_SETTING_DOC_DIALOG,
    UPDATE_SETTINGS_FILTER,
    AUTHORIZING_SETTING,
    SETTING_AUTHORIZATION_FAILED, SETTING_AUTHORIZED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    settings: [],
    totalSettings: 0,
    loadingSettings: false,
    fetchingSetting: false,
    updatingSetting: false,
    authorizingSetting: false,
    selectedSetting: {},
    settingPayments: [],
    settingUser: {},
    settingInfoOpened: false,
    settingEditOpened: false,
    settingAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    gateway: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_SETTINGS_LIST: {
            return {...state, loadingSettings: true, redraw: false};
        }
        case SETTINGS_LIST_FETCHED: {
            return {...state, loadingSettings: false, redraw: false, backToFirstPage: false, settings: action.payload.list ?? [], totalSettings: action.payload.totalCount};
        }
        case FETCHING_SETTING_INFO: {
            return {...state, fetchingSetting: true};
        }
        case SETTING_INFO_FETCHED: {
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_setting_verified: user.national_setting_verified,
                selfie_verified: user.selfie_verified,
            };
            return {...state, fetchingSetting: false, selectedSetting: action.payload.data.setting, settingPayments: action.payload.data.payments, settingUser: user};
        }
        case SETTING_INFO_FAILED: {
            return {...state, fetchingSetting: false};

        }
        case UPDATING_SETTING_INFO: {
            return {...state, updatingSetting: true};
        }
        case SETTING_INFO_UPDATE_FAILED: {
            return {...state, updatingSetting: false};
        }
        case SETTING_INFO_UPDATED: {
            return {...state, updatingSetting: false, settingEditOpened: false, redraw: true};
        }
        case AUTHORIZING_SETTING: {
            return {...state, authorizingSetting: true};
        }
        case SETTING_AUTHORIZATION_FAILED: {
            return {...state, authorizingSetting: false, settingAuthOpened: false, redraw: true};
        }
        case SETTING_AUTHORIZED: {
            return {...state, authorizingSetting: false};
        }
        case UPDATE_SETTINGS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_SETTING: {
            return {...state, deletingSetting: true};
        }
        case SETTING_DELETE_FAILED: {
            return {...state, deletingSetting: false};
        }
        case SETTING_DELETED: {
            return {...state, deletingSetting: false, redraw: true};
        }

        case SWITCH_SETTING_INFO_DIALOG: {
            return {...state, settingInfoOpened: action.payload.state, selectedSetting: action.payload.data ?? {}, };
        }
        case SWITCH_SETTING_EDIT_DIALOG: {
            return {...state, settingEditOpened: action.payload.state, selectedSetting: action.payload.data ?? {}, };
        }
        case SWITCH_SETTING_DOC_DIALOG: {
            return {...state, settingAuthOpened: action.payload.state, selectedSetting: action.payload.data ?? {}, };
        }
        case REDRAW_SETTINGS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

