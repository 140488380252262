import React from "react";
import IntlMessages from "../../../../utils/IntlMessages";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from '@material-ui/core/styles';
import { Box, fade, InputBase } from '@material-ui/core';
import {switchTerminal} from "../../../../../redux/actions/CurrencyActions";
const styles = (theme) => ({
    searchRoot: {
        position: 'relative',
        width: 260,
        [theme.breakpoints.up('md')]: {
            width: 350,
        },
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            color: theme.palette.text.white,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
        },
        '& .MuiInputBase-root': {
            width: '100%',
            borderRadius: 30,
        },
        '& .MuiFilledInput-underline:before' : {
          borderBottom: 'unset'
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.text.white
        },
        '& .MuiInputBase-input': {
            height: 48,
            borderRadius: 30,
            backgroundColor: fade(theme.palette.common.dark, 0.38),
            color: fade(theme.palette.common.white, 0.7),
            boxSizing: 'border-box',
            // padding: '5px 15px 5px 50px',
            transition: 'all 0.3s ease',
            '&:focus': {
                backgroundColor: fade(theme.palette.common.dark, 0.58),
                color: fade(theme.palette.common.white, 0.7),
            },
        },
    },
});

class TerminalSelector extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state = {
            inputs: {
                value: '',
                terminal: 1

            },
            formErrors: {},
        };

    }
    handleChange = (e) => {
        const {name, checked, value} = e.target;
        const {inputs} = this.state;
        inputs[name] = value || checked;
        this.setState({inputs: inputs});
        this.props.switchTerminal({info: inputs.terminal});
        // console.log("settings", name, checked, value);
    };

    render() {
        const {terminals, classes, selectedTerminal} = this.props;
        const {terminal} = this.state.inputs;
        return(
            <TextField className={classes.searchRoot}
                // id="terminal"
                label={<IntlMessages id={"title.terminal_select"} />}
                select
                onChange={this.handleChange}
                value={selectedTerminal}
                fullWidth
                variant="filled"
                name='terminal'
                size="small"
            >
                {terminals.map((terminal, index) => (
                    <MenuItem key={terminal.terminal_id} value={terminal.terminal_id} selected={index === 0}>
                        {terminal.en_title}
                        {/*<IntlMessages id={option.title} />*/}
                    </MenuItem>
                ))}
            </TextField>
        )
    }
}
const mapStateToProps = ({auth, currency}) => {
    const {selectedTerminal} = currency;
    const {terminals} = auth;
    // console.log('===> Terminals', terminals);
    return {terminals, selectedTerminal};
};

export default connect(mapStateToProps, {switchTerminal})(withStyles(styles)(TerminalSelector));