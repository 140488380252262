import {


    FETCHING_WALLET_LIST,
    WALLET_LIST_FETCHED,
    UPDATING_WALLET_INFO,
    WALLET_INFO_UPDATED,
    WALLET_INFO_UPDATE_FAILED,
    FETCHING_WALLET_INFO,
    WALLET_INFO_FETCHED,
    WALLET_INFO_FAILED,
    SWITCH_WALLET_EDIT_DIALOG,
    SWITCH_WALLET_INFO_DIALOG,
    SWITCH_WALLET_BLOCK_BALANCE_DIALOG,
    BLOCKING_BALANCE_WALLET,
    WALLET_BALANCE_BLOCK_FAILED,
    WALLET_BALANCE_BLOCKED,
    REDRAW_WALLETS_LIST,
    UPDATE_WALLETS_FILTER, FETCHING_WALLETS_STATS, WALLETS_STATS_FETCHED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    redraw: false,
    backToFirstPage: false,
    loadingWallets: false,
    totalWallets: 0,
    wallets: [],
    editDialogOpened: false,
    updatingWallet: false,
    fetchingWallet: false,
    selectedWallet: {},
    infoDialogOpened: false,
    walletBlockBalanceDialogOpened: false,
    blockingWallet: false,
    loadingWalletsStats: false,
    walletsStats: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_WALLET_LIST: {
            return {...state, loadingWallets: true, redraw: false};
        }
        // case USE_GATEWAY: {
        //     return {...state, gatewayUsed: false};
        // }
        case WALLET_LIST_FETCHED: {
            return {...state, loadingWallets: false, redraw: false, backToFirstPage: false, wallets: action.payload.list ?? [], totalWallets: action.payload.totalCount};
        }
        case UPDATING_WALLET_INFO: {
            return {...state, updatingWallet: true};
        }
        case WALLET_INFO_UPDATE_FAILED: {
            return {...state, updatingWallet: false};
        }
        case WALLET_INFO_UPDATED: {
            return {...state, updatingWallet: false, editDialogOpened: false, redraw: true};
        }
        case FETCHING_WALLET_INFO: {
            return {...state, fetchingWallet: true};
        }
        case WALLET_INFO_FETCHED: {
            return {...state, fetchingWallet: false, selectedWallet: action.payload.data};
        }
        case WALLET_INFO_FAILED: {
            return {...state, fetchingWallet: false};
        }
        case SWITCH_WALLET_EDIT_DIALOG: {
            return {...state, editDialogOpened: action.payload.state, selectedWallet: action.payload.data ?? {}, };
        }
        case SWITCH_WALLET_INFO_DIALOG: {
            return {...state, infoDialogOpened: action.payload.state, selectedWallet: action.payload.data ?? {}, };
        }
        case SWITCH_WALLET_BLOCK_BALANCE_DIALOG: {
            return {...state, walletBlockBalanceDialogOpened: action.payload.state, selectedWallet: action.payload.data ?? {}, };
        }
        case BLOCKING_BALANCE_WALLET: {
            return {...state, blockingWallet: true};
        }
        case WALLET_BALANCE_BLOCK_FAILED: {
            return {...state, blockingWallet: false};
        }
        case WALLET_BALANCE_BLOCKED: {
            return {...state, blockingWallet: false, walletBlockBalanceDialogOpened: false, redraw: true};
        }
        case FETCHING_WALLETS_STATS: {
            return {...state, loadingWalletsStats: true};
        }
        case WALLETS_STATS_FETCHED: {
            return {...state, loadingWalletsStats: false, walletsStats: action.payload && action.payload.list ? action.payload.list : [], redraw: true};
        }
        case REDRAW_WALLETS_LIST: {
            return {...state, redraw: true, };
        }
        case UPDATE_WALLETS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        default:
            return state;
    }
}

