import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Components from './Components';
import Apps from './Apps';
import Extensions from './Extensions';
import Charts from './Charts';
import Maps from './Maps';
import Widgets from './Widgets';
import Metrics from './Metrics';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExtraPages from './ExtraPages';
import TourGuide from './TourGuide';
import UserManagements from './UserManagement';
import Authentication from './Authentication';
import CardManagements from "./CardManagement";
import OrderManagements from "./OrderManagement";
import Transactions from "./Transactions";
import GatewayManagement from "./GatewayManagement";
import PaymentManagement from "./PaymentManagement";
import TicketManagement from "./TicketManagement";
import PriceManagements from "./PriceManagement";
import Blacklist from "./Blacklist";
import InquiryManagement from "./InquiryManagement";
import LogManagement from "./LogManagement";
import Dashboard from "./Dashboard";
import SupplyManagement from "./SupplyManagements";
import ProfitManagement from "./ProfitManagement";
import CurrencyManagement from "./CurrencyManagement";
import NetworkManagement from "./NetworkManagement";
import NoticeManagement from "./NoticeManagement";
import AffiliateManagement from "./AffiliateManagement";
import PrizeManagement from "./PrizeManagement"
import TerminalManagement from "./TerminalManagement";
import DiscountManagement from "./DiscountManagement";
import WalletManagement from "./WalletManagement";
import FavAddressManagement from "./FavAddressManagement";



const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute path="/user_manager" component={UserManagements} />
        <RestrictedRoute path="/authentication" component={Authentication} />
        <RestrictedRoute path="/credit_card" component={CardManagements} />
        <RestrictedRoute path="/order_manager" component={OrderManagements}/>
        <RestrictedRoute path="/transactions" component={Transactions}/>
        <RestrictedRoute path="/gateway_manager" component={GatewayManagement}/>
        <RestrictedRoute path="/log_manager" component={LogManagement}/>
        <RestrictedRoute path="/payment_manager" component={PaymentManagement}/>
        <RestrictedRoute path="/ticket_manager" component={TicketManagement}/>
        <RestrictedRoute path="/price_manager" component={PriceManagements}/>
        <RestrictedRoute path="/supply_manager" component={SupplyManagement}/>
        <RestrictedRoute path="/inquiry_manager" component={InquiryManagement}/>
        <RestrictedRoute path="/profit_manager" component={ProfitManagement}/>
        <RestrictedRoute path="/currency_manager" component={CurrencyManagement}/>
        <RestrictedRoute path="/network_manager" component={NetworkManagement}/>
        <RestrictedRoute path="/blacklist" component={Blacklist}/>
        <RestrictedRoute path="/notice_manager" component={NoticeManagement}/>
        <RestrictedRoute path="/affiliate" component={AffiliateManagement}/>
        <RestrictedRoute path="/prize_manager" component={PrizeManagement}/>
        <RestrictedRoute path="/discount_manager" component={DiscountManagement}/>
        <RestrictedRoute path="/terminal_manager" component={TerminalManagement}/>
        <RestrictedRoute path="/wallet_manager" component={WalletManagement}/>
        <RestrictedRoute path="/fav_address_manager" component={FavAddressManagement}/>
        <Route path="/widgets" component={Widgets} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/components" component={Components} />
        <Route path="/extensions" component={Extensions} />
        <Route path="/visualization/chart" component={Charts} />
        <Route path="/visualization/map" component={Maps} />
        <Route path="/extra-pages" component={ExtraPages} />
        <Route path="/apps" component={Apps} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
      </Switch>

      {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )}
      {/*<NotificationContainer />*/}
    </React.Fragment>
  );
};

export default Routes;
