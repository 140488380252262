import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const WalletManagement = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/clients`} />
                <Route path={`${requestedUrl}/all`} component={lazy(() => import('./AllAddresses'))} />
                <Route component={lazy(() => import('../ExtraPages/404'))} />
            </Switch>
        </Suspense>
    );
};

export default WalletManagement;
