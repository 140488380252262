
export const AES_KEY = '9a10c9bcdc12693eb3cf566a90d3670bd0604a58dd758e146956baf596e97c7d';

export const TRANSACTION_TYPES = {
  active: 2,
  inactive: 0
};
export const DIGITAL_CURRENCIES = {
    USDT: 11,
    PM_USD: 12,
    PV_USD: 13,
    PM_EURO: 14,
    PV_EURO: 15,
    WM: 16,
    PAYMER: 17,
    PS_VOUCHER: 19,
    PAYEER: 20,
    GOLD:     1193,
    UV_USD:   1194,
    UUSD:     1195,
    CRP:      1196,
    UV_CRP:   1197,
};

export const FIATS = {
    USDT: 11,
}

export const BASE_CURRENCIES = {
    IRR: 18,
}

export const STATUS = {
    ACCEPTED_BY_ADMIN: 11,
    ACCEPTED_BY_EX_API: 12,
    ACCEPTED_BY_SYSTEM: 13,

    REJECTED_BY_ADMIN: -1,
    REJECTED_BY_EX_API: -2,
    REJECTED_BY_SYSTEM: -3,
    REJECTED_BY_USER: -4,
    EXPIRED: -5,

    DELETED_BY_ADMIN: -6,
    DELETED_BY_EX_API: -7,
    DELETED_BY_SYSTEM: -8,
    DELETED_BY_USER: -9,

    ADMIN_PROGRESS: 1,
    EX_API_PROGRESS: 2,
    SYSTEM_PROGRESS: 3,
    USER_PROGRESS: 4,


};

export const AUTH_TYPE = {
    USER_INFO: 1,
    RESIDENTIAL: 2
};

export const PRIZE_USAGE = {
    BUY: 1,
    SELL: 2,
    DEPOSIT: 5,
    WITHDRAW: 6,
};

export const PRIZE_TYPES = {
    TYPE_AUTH_PRIZE: 1,
    TYPE_DEPOSIT_PRIZE: 2,
    TYPE_SUBSET_AUTH_PRIZE: 3,
    TYPE_BUY_PRIZE: 4,
    TYPE_FIRST_BUY_PRIZE: 5,
};

export const DISCOUNT_TYPE = {
    LEVEL_DISCOUNT: 1,
};

export const DISCOUNT_EFFECT_ON = {
    TOTAL: 1,
    COMMISSION: 2,
    BOTH: 3,
};

export const DISCOUNT_APPLY_AS = {
    DIRECT: 1,
    CODE: 2,
};

export const AVAILABLE_DISCOUNTS = {
    NONE: 0,
    DIRECT: 1,
    CODE: 2,
    ALL: 10
};

export const TRANSFER_TYPES = {
    BUY: 1,
    SELL: 2,
    DEPOSIT: 5,
    WITHDRAW: 6,
    REFUND: 7,
    SUBSET_PROFIT: 8,
    PRIZE: 10,
};

export const PAYMENT_TYPES = {
    ORIGIN_TO_DESTINATION: 1,
    DESTINATION_TO_ORIGIN: 2,
};

export const GATEWAYS = {
    ONLINE: 1,
    C2C: 2,
    PERFECT_MONEY: 3,
    WEB_MONEY: 5,
    BINANCE: 6,
    OFFLINE: 7,
    PS: 8,
    PAYEER: 9,
    COINEX: 10,
    KUCOIN: 11,
    SHOP: 12,
    UTOPIA: 13,
};

export const MANAGEMENT_UNITS = {
    3: "فنی",
    4: "مالی",
    5: "انتقادات و پیشنهادت"
};

export const BLACKLIST_TYPES = {
    IP: 1,
    WALLET: 2,
    MOBILE: 3,
    NATIONAL_ID: 4,
    EMAIL: 5
};

export const TWO_STEP_METHODS = {
    EMAIL: 1,
    SMS: 2,
    GA: 3,
    NONE: 4
};

export const USER_ROLES = {
    CLIENT: 1,
    ADMIN: 2,
    COOPERATOR: 3,
    NONE: 4
};

export const LOG_ACTIONS = {
    ACTION_LOGIN: 1,
    ACTION_REGISTER: 2,
    ACTION_LOGOUT: 3,
    ACTION_FORGOT_PASSWORD: 4,
    ACTION_CHANGE_PASSWORD: 5,
    ACTION_CHANGE_MOBILE: 6,
    ACTION_CHANGE_EMAIL: 7,
    ACTION_CHANGE_USER_INFO: 8,
    ACTION_AUTH_EMAIL: 9,
    ACTION_AUTH_MOBILE: 10,
    ACTION_AUTH_INFO_REQUEST: 11,
    ACTION_AUTH_STATUS_CHANGED: 12,
    ACTION_DOCUMENT_CHANGED: 13,
    ACTION_BLOCK_USER: 14,
    ACTION_ADD_CARD: 15,
    ACTION_REMOVE_CARD: 16,
    ACTION_EDIT_CARD: 17,
    ACTION_VERIFY_CARD: 18,
};
export const LOG_RESULTS = {
    SUCCESS : 1,
    FAIL: 0
};

export const BINANCE_WITHDRAW_STATUS = {
    WAITING : 0,
    AWAITING: 2,
    CANCELLED: 1,
    REJECTED: 3,
    PROCESSING: 4,
    FAIL: 5,
    COMPLETE: 6

};
export const BINANCE_DEPOSITS_STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    WAITING: 6

};

export const PROFIT_TYPES = {
    WAGE: 1,
    COMMISSION: 2

};
export const NOTICE_SHOW_IN = {
    DEPOSITS_LIST: '/panel/wallet/deposit',
    WITHDRAWS_LIST: '/panel/wallet/withdraw',
    TRANSACTIONS_LIST: '/panel/wallet/transactions',
    TRANSFERS_LIST: '/panel/exchange/transactions',
    NEW_EXCHANGE: '/panel/exchange/shopping',
    DASHBOARD: '/panel/dashboard',
    EMAIL_AUTHORIZATION: '/panel/authorization/email',
    MOBILE_AUTHORIZATION: '/panel/authorization/mobile',
    INFO_AUTHORIZATION: '/panel/authorization/user',
    CREDIT_CARDS: '/panel/creditcard',
    REFERRAL: '/panel/referral',
    USER_NOTICES: '/panel/notices',
    SETTINGS: '/panel/setting',
    HOMEPAGE: '/home',
    ANY: 'any'
};
export const NOTICE_TYPES = {
    NOTICE_TYPE_NORMAL : 1,
    NOTICE_TYPE_WARNING: 2,
    NOTICE_TYPE_DANGER: 3
};
export const NOTICE_UNITS = {
    ADMIN_NOTICE : 0,
    AFFILIATION : 1,
    VERIFY_INFO : 2,
    TICKET_ANSWERED : 3,
    TICKET_CLOSED : 4,
    WITHDRAW_SENT : 5,
    WITHDRAW_TIME_CHANGED : 6,
    WITHDRAW_CANCELED : 7,
    CARD_GRANTED : 8,
    AUTH_REJECTED : 9,
    AUTH_GRANTED : 10,
    VERIFY_MOBILE : 11,
    VERIFY_EMAIL : 12,
    EMAIL_VERIFIED : 13,
    SELL_ADMIN_PROGRESS : 14,
    BUY_ORDER_DONE : 15,
    PASSWORD_CHANGED : 16,
    '2FA_CHANGED' : 17,
    MAX_BUY_INCREASED : 18,
    SELL_ORDER_DONE : 19,
};

export const SYSTEM_NOTICE_UNIT = {
    SYSTEM: 1,
    MESSAGE: 2,
    TRADING: 3,
    ACCOUNTING: 4
}
export const SHOW_TYPE = {
  SHOW_TYPE_MODAL: 1,
  SHOW_TYPE_INLINE: 2
};

