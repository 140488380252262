
import {
    CARDS_LIST_FETCHED,
    FETCHING_CARDS_LIST,
    REDRAW_CARDS_LIST,
    CARDS_LIST_FAILED,
    FETCHING_CARD_INFO,
    CARD_INFO_FETCHED,
    CARD_INFO_FAILED,
    SWITCH_CARD_INFO_DIALOG,
    SWITCH_CARD_EDIT_DIALOG,
    UPDATING_CARD_INFO,
    SWITCH_CARD_DOC_DIALOG,
    DELETING_CARD,
    CARD_DELETED,
    CARD_DELETE_FAILED,
    CARD_INFO_UPDATED,
    CARD_INFO_UPDATE_FAILED,
    UPDATE_CARDS_FILTER,
    UPDATING_USER_DOC,
    USER_DOC_UPDATED,
    USER_DOC_UPDATE_FAILED,
    CARD_AUTHORIZATION_FAILED,
    AUTHORIZING_CARD,
    CARD_AUTHORIZED,
    SWITCH_ADD_CARD_DIALOG,
    ADDING_NEW_USER,
    NEW_USER_ADDED, NEW_USER_ADD_FAILED, ADDING_NEW_CARD, NEW_CARD_ADD_FAILED, NEW_CARD_ADDED,
} from "../../@jumbo/constants/ActionTypes";
import {
    DELETE_CARD,
    EDIT_CARD,
    CARD_MANAGEMENT,
    DOCUMENT_AUTHORIZATION,
    CARD_AUTHORIZATION, USER_MANAGEMENT, ADD_CARD
} from "../../@jumbo/constants/Units";
import {deleteOne, dispatchAction, fetchList, fetchOne, MULTIPART_HEADER, updateOne} from "../../@jumbo/utils/Api";

export const updateCardVerify = (cardID, data) => {
    return dispatchAction({
        url: 'credit_cards/authorize/' + cardID ,
        params: data,
        fetchingAction: AUTHORIZING_CARD,
        successAction: CARD_AUTHORIZED,
        failAction: CARD_AUTHORIZATION_FAILED,
        unitName: CARD_AUTHORIZATION,
        showError: true,
        onSuccess: function (data, dispatch) {
        }
    })
}

export const deleteCard = (id) => {
    return deleteOne({
        url: 'credit_cards/' + id,
        params: {},
        fetchingAction: DELETING_CARD,
        successAction: CARD_DELETED,
        failAction: CARD_DELETE_FAILED,
        unitName: DELETE_CARD,
        showError: true
    })
};

export const updateCard = (id, params) => {
    return updateOne({
        url: 'credit_cards/' + id,
        params: params,
        fetchingAction: UPDATING_CARD_INFO,
        successAction: CARD_INFO_UPDATED,
        failAction: CARD_INFO_UPDATE_FAILED,
        unitName: EDIT_CARD,
        showError: true
    })
};

export const getCard = (id) => {
    return fetchOne({
        url: 'credit_cards/' + id,
        params: {},
        fetchingAction: FETCHING_CARD_INFO,
        successAction: CARD_INFO_FETCHED,
        failAction: CARD_INFO_FAILED,
        unitName: CARD_MANAGEMENT,
        showError: true
    })
};

export const addCard = (id, params) => {
    console.log("add card action", params);
    return dispatchAction({
        url: 'credit_cards/create',
        params: params,
        fetchingAction: ADDING_NEW_CARD,
        successAction: NEW_CARD_ADDED,
        failAction: NEW_CARD_ADD_FAILED,
        unitName: ADD_CARD,
        showError: true
    })
};

export const getCardsList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'credit_cards/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_CARDS_LIST,
        successAction: CARDS_LIST_FETCHED,
        failAction: CARDS_LIST_FAILED,
        unitName: CARD_MANAGEMENT,
        showError: true
    })

};

export const emptyCards = () => {
    console.log('applyListFilter')
    return (dispatch) => {
        dispatch({type: CARDS_LIST_FETCHED, payload: {list: [], totalCount: 0}});
    }
};

export const applyListFilter = (filters, sort) => {
    console.log('applyListFilter')
    return (dispatch) => {
        dispatch({type: UPDATE_CARDS_FILTER, payload: {data: {filters: filters, sort: sort}}});
    }
};

export const switchInfoDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CARD_INFO_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchEditDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CARD_EDIT_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchAddCardDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_ADD_CARD_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchCardVerifyDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CARD_DOC_DIALOG, payload: {state: state, data: info}});
    }
};



