import {
    GATEWAYS_LIST_FETCHED,
    FETCHING_GATEWAYS_LIST,
    REDRAW_GATEWAYS_LIST,
    GATEWAYS_LIST_FAILED,
    FETCHING_GATEWAY_INFO,
    GATEWAY_INFO_FETCHED,
    GATEWAY_INFO_FAILED,
    SWITCH_GATEWAY_INFO_DIALOG,
    SWITCH_GATEWAY_EDIT_DIALOG,
    UPDATING_GATEWAY_INFO,
    GATEWAY_INFO_UPDATED,
    GATEWAY_INFO_UPDATE_FAILED,
    DELETING_GATEWAY,
    GATEWAY_DELETED,
    GATEWAY_DELETE_FAILED,
    SWITCH_GATEWAY_DOC_DIALOG,
    AUTHORIZING_GATEWAY,
    GATEWAY_AUTHORIZED,
    GATEWAY_AUTHORIZATION_FAILED,
    UPDATE_GATEWAYS_FILTER,
    BINANCE_DEPOSITS_LIST_FETCHED,
    FETCHING_BINANCE_DEPOSITS_LIST,
    BINANCE_WITHDRAWS_LIST_FETCHED,
    FETCHING_BINANCE_WITHDRAWS_LIST,
    SWITCH_GATEWAY_BANKS_EDIT_DIALOG, UPDATING_GATEWAY_BANKS_INFO, GATEWAY_BANKS_UPDATED, GATEWAY_BANKS_UPDATE_FAILED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    gateways: [],
    binanceDeposits: [],
    totalGateways: 0,
    totalDeposits: 0,
    loadingGateways: false,
    loadingDeposits: false,
    fetchingGateway: false,
    updatingGateway: false,
    updatingGatewayBanks: false,
    authorizingGateway: false,
    selectedGateway: {},
    banks: [],
    gatewayPayments: [],
    gatewayUser: {},
    gatewayInfoOpened: false,
    gatewayEditOpened: false,
    gatewayAuthOpened: false,
    gatewayBanksEditOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    redrawDeposits: false,
    gateway: {},
    loadingWithdrawas: false,
    redrawWithdraws: false,
    binanceWithdraws: [],
    totalWithdraws: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_GATEWAYS_LIST: {
            return {...state, loadingGateways: true, redraw: false};
        }
        case FETCHING_BINANCE_DEPOSITS_LIST: {
            return {...state, loadingDeposits: true, redrawDeposits: false};
        }
        case FETCHING_BINANCE_WITHDRAWS_LIST: {
            return {...state, loadingWithdrawas: true, redrawWithdraws: false};
        }
        case GATEWAYS_LIST_FETCHED: {
            return {...state, loadingGateways: false, redraw: false, backToFirstPage: false, gateways: action.payload.list ?? [], totalGateways: action.payload.totalCount};
        }
        case BINANCE_DEPOSITS_LIST_FETCHED: {
            return {...state, loadingDeposits: false, redrawDeposits: false, backToFirstPage: false, binanceDeposits: action.payload.list ?? [], totalDeposits: action.payload.totalCount};
        }
        case BINANCE_WITHDRAWS_LIST_FETCHED: {
            return {...state, loadingWithdrawas: false, redrawWithdraws: false, backToFirstPage: false, binanceWithdraws: action.payload.list ?? [], totalWithdraws: action.payload.totalCount};
        }
        case FETCHING_GATEWAY_INFO: {
            return {...state, fetchingGateway: true};
        }
        case GATEWAY_INFO_FETCHED: {

            return {...state, fetchingGateway: false, selectedGateway: action.payload.data.gateway, banks: (action.payload.data.banks ?? state.banks)};
        }
        case GATEWAY_INFO_FAILED: {
            return {...state, fetchingGateway: false};

        }
        case UPDATING_GATEWAY_INFO: {
            return {...state, updatingGateway: true};
        }
        case GATEWAY_INFO_UPDATE_FAILED: {
            return {...state, updatingGateway: false};
        }
        case GATEWAY_INFO_UPDATED: {
            return {...state, updatingGateway: false, gatewayEditOpened: false, redraw: true};
        }
        case UPDATING_GATEWAY_BANKS_INFO: {
            return {...state, updatingGatewayBanks: true};
        }
        case GATEWAY_BANKS_UPDATE_FAILED: {
            return {...state, updatingGatewayBanks: false};
        }
        case GATEWAY_BANKS_UPDATED: {
            return {...state, updatingGatewayBanks: false, gatewayBanksEditOpened: false};
        }
        case AUTHORIZING_GATEWAY: {
            return {...state, authorizingGateway: true};
        }
        case GATEWAY_AUTHORIZATION_FAILED: {
            return {...state, authorizingGateway: false, gatewayAuthOpened: false, redraw: true};
        }
        case GATEWAY_AUTHORIZED: {
            return {...state, authorizingGateway: false};
        }
        case UPDATE_GATEWAYS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_GATEWAY: {
            return {...state, deletingGateway: true};
        }
        case GATEWAY_DELETE_FAILED: {
            return {...state, deletingGateway: false};
        }
        case GATEWAY_DELETED: {
            return {...state, deletingGateway: false, redraw: true};
        }

        case SWITCH_GATEWAY_INFO_DIALOG: {
            return {...state, gatewayInfoOpened: action.payload.state, selectedGateway: action.payload.data ?? {}, };
        }
        case SWITCH_GATEWAY_EDIT_DIALOG: {
            return {...state, gatewayEditOpened: action.payload.state, selectedGateway: action.payload.data ?? {}, };
        }
        case SWITCH_GATEWAY_DOC_DIALOG: {
            return {...state, gatewayAuthOpened: action.payload.state, selectedGateway: action.payload.data ?? {}, };
        }
         case SWITCH_GATEWAY_BANKS_EDIT_DIALOG: {
            return {...state, gatewayBanksEditOpened: action.payload.state, selectedGateway: action.payload.data ?? {}, };
        }
        case REDRAW_GATEWAYS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

