import {
    DISCOUNTS_LIST_FETCHED,
    FETCHING_DISCOUNTS_LIST,
    REDRAW_DISCOUNTS_LIST,
    FETCHING_DISCOUNT_INFO,
    DISCOUNT_INFO_FETCHED,
    DISCOUNT_INFO_FAILED,
    SWITCH_DISCOUNT_INFO_DIALOG,
    SWITCH_DISCOUNT_EDIT_DIALOG,
    UPDATING_DISCOUNT_INFO,
    DISCOUNT_INFO_UPDATED,
    DISCOUNT_INFO_UPDATE_FAILED,
    DELETING_DISCOUNT,
    DISCOUNT_DELETED,
    DISCOUNT_DELETE_FAILED,
    UPDATE_DISCOUNTS_FILTER, FETCHING_USER_DISCOUNTS, USER_DISCOUNTS_FETCHED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    discounts: [],
    userDiscounts: [],
    totalDiscounts: 0,
    loadingDiscounts: false,
    loadingUserDiscounts: false,
    fetchingDiscount: false,
    updatingDiscount: false,
    authorizingDiscount: false,
    selectedDiscount: {},
    scoreSettings: {},
    discountInfoOpened: false,
    discountEditOpened: false,
    redraw: false,
    backToFirstPage: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_DISCOUNTS_LIST: {
            return {...state, loadingDiscounts: true, redraw: false};
        }
        case DISCOUNTS_LIST_FETCHED: {
            return {...state, loadingDiscounts: false, redraw: false, backToFirstPage: false, discounts: action.payload.list ?? [], totalDiscounts: action.payload.totalCount};
        }
        case FETCHING_USER_DISCOUNTS: {
            return {...state, loadingUserDiscounts: true, userDiscounts: [],};
        }
        case USER_DISCOUNTS_FETCHED: {
            return {...state, loadingUserDiscounts: false, userDiscounts: action.payload.list ?? [], scoreSettings: action.payload.data.score_settings};
        }
        case FETCHING_DISCOUNT_INFO: {
            return {...state, fetchingDiscount: true};
        }
        case DISCOUNT_INFO_FETCHED: {

            return {...state, fetchingDiscount: false, selectedDiscount: action.payload.data.discount, banks: (action.payload.data.banks ?? state.banks)};
        }
        case DISCOUNT_INFO_FAILED: {
            return {...state, fetchingDiscount: false};

        }
        case UPDATING_DISCOUNT_INFO: {
            return {...state, updatingDiscount: true};
        }
        case DISCOUNT_INFO_UPDATE_FAILED: {
            return {...state, updatingDiscount: false};
        }
        case DISCOUNT_INFO_UPDATED: {
            return {...state, updatingDiscount: false, discountEditOpened: false, redraw: true};
        }
        case UPDATE_DISCOUNTS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_DISCOUNT: {
            return {...state, deletingDiscount: true};
        }
        case DISCOUNT_DELETE_FAILED: {
            return {...state, deletingDiscount: false};
        }
        case DISCOUNT_DELETED: {
            return {...state, deletingDiscount: false, redraw: true};
        }

        case SWITCH_DISCOUNT_INFO_DIALOG: {
            return {...state, discountInfoOpened: action.payload.state, selectedDiscount: action.payload.data ?? {}, };
        }
        case SWITCH_DISCOUNT_EDIT_DIALOG: {
            return {...state, discountEditOpened: action.payload.state, selectedDiscount: action.payload.data ?? {}, };
        }
        case REDRAW_DISCOUNTS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

