import {
    ADDING_NEW_ADMIN,
    ADMIN_DELETE_FAILED,
    ADMIN_DELETED,
    ADMIN_INFO_FAILED,
    ADMIN_INFO_FETCHED,
    ADMIN_INFO_UPDATE_FAILED,
    ADMIN_INFO_UPDATED, APPLY_FROZEN_USERS_FILTER,
    DELETING_ADMIN,
    FETCHING_ADMIN_INFO,
    FETCHING_FROZEN_USERS_LIST,
    FETCHING_LEVELS_INFOS,
    FETCHING_TERMINAL_ADMINS_LIST,
    FETCHING_TERMINAL_INFOS,
    FROZEN_USERS_LIST_FETCHED,
    FROZEN_USERS_UPDATE_FAILED,
    FROZEN_USERS_UPDATED, LEVEL_INFO_UPDATE_FAIL, LEVEL_INFO_UPDATED,
    LEVELS_INFOS_FETCHED,
    NEW_ADMIN_ADD_FAILED,
    NEW_ADMIN_ADDED,
    SWITCH_ADD_ADMIN_DIALOG,
    SWITCH_ADMIN_EDIT_DIALOG,
    SWITCH_ADMIN_INFO_DIALOG,
    SWITCH_USERS_DIALOG,
    TERMINAL_ADMINS_LIST_FETCHED,
    TERMINAL_INFO_UPDATE_FAIL,
    TERMINAL_INFO_UPDATED,
    TERMINAL_INFOS_FETCHED,
    TICKET_SIGNATURE_UPDATE_FAILED,
    TICKET_SIGNATURE_UPDATED,
    UPDATE_TERMINAL_ADMINS_FILTER,
    UPDATING_ADMIN_INFO,
    UPDATING_FROZEN_USERS, UPDATING_LEVEL_INFO,
    UPDATING_TERMINAL_INFO,
    UPDATING_TICKET_SIGNATURE,

} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    terminals: [],
    totalTerminals: 0,
    loadingTerminals: false,
    redraw: false,
    backToFirstPage: false,
    adminInfoOpened: false,
    adminEditOpened: false,
    addAdminOpened: false,
    loadingTerminalAdmins: false,
    redrawTerminalAdmins: false,
    terminalAdmins: [],
    totalTerminalAdmins: 0,
    fetchingAdminInfo: false,
    selectedAdmin: {},
    addingAdmin: false,
    updatingAdminInfo: false,
    deletingAdmin: false,
    updatingTicketSignature: false,
    terminal_admin: {},
    usersDialogOpened: false,
    loadingFrozenUsers: false,
    redrawFrozenUsers: false,
    frozenUsersList: [],
    totalFrozenUsers: 0,
    updatingFrozenUsers: false,
    frozenUsers: [],
    everyBuyUsers: [],
    loadingLevels: false,
    levels: [],
    totalLevels: 0,
    redrawLevels: false,
    updatingLevel: false,
    levelUpdated: false,
    settingsTerminalID: 0,
    // terminalID: ""


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_TERMINAL_INFOS: {
            return {...state, loadingTerminals: !state.smoothMode, redraw: false};
        }
        case TERMINAL_INFOS_FETCHED: {
            return {...state, loadingTerminals: false, redraw: false, backToFirstPage: false, smoothMode: false, terminals: action.payload.list ?? [], totalTerminals: action.payload.totalCount};
        }
        case FETCHING_LEVELS_INFOS: {
            return {...state, loadingLevels: !state.smoothMode, redrawLevels: false};
        }
        case LEVELS_INFOS_FETCHED: {
            return {...state, loadingLevels: false, redrawLevels: false, backToFirstPage: false, smoothMode: false, levels: action.payload.list ?? [], totalLevels: action.payload.totalCount};
        }
        case UPDATING_TERMINAL_INFO: {
            return {...state, updatingTerminal: true};
        }
        case TERMINAL_INFO_UPDATE_FAIL: {
            return {...state, updatingTerminal: false, terminalUpdated: false};
        }
        case TERMINAL_INFO_UPDATED: {
            return {...state, updatingTerminal: false, terminalUpdated: true, redraw: true};
        }
        case UPDATING_LEVEL_INFO: {
            return {...state, updatingLevel: true};
        }
        case LEVEL_INFO_UPDATE_FAIL: {
            return {...state, updatingLevel: false, levelUpdated: false};
        }
        case LEVEL_INFO_UPDATED: {
            return {...state, updatingLevel: false, levelUpdated: true, redrawLevels: true};
        }

        case FETCHING_TERMINAL_ADMINS_LIST: {
            return {...state, loadingTerminalAdmins: !state.smoothMode, redrawTerminalAdmins: false};
        }
        case TERMINAL_ADMINS_LIST_FETCHED: {
            return {...state, loadingTerminalAdmins: false, redrawTerminalAdmins: false, backToFirstPage: false, smoothMode: false, terminalAdmins: action.payload.list ?? [], totalTerminalAdmins: action.payload.totalCount};
        }

        case UPDATING_FROZEN_USERS: {
            return {...state, updatingFrozenUsers: true};
        }
        case FROZEN_USERS_UPDATED: {
            return {...state, updatingFrozenUsers: false, redrawFrozenUsers: true, usersDialogOpened: false};
        }
        case FROZEN_USERS_UPDATE_FAILED: {
            return {...state, updatingFrozenUsers: false, };
        }

        case APPLY_FROZEN_USERS_FILTER: {
            return {...state, redrawFrozenUsers: true, backToFirstPage: true, };
        }
        case FETCHING_FROZEN_USERS_LIST: {
            return {...state, loadingFrozenUsers: !state.smoothMode, redrawFrozenUsers: false};
        }
        case FROZEN_USERS_LIST_FETCHED: {
            return {...state, loadingFrozenUsers: false, redrawFrozenUsers: false, backToFirstPage: false, smoothMode: false,
                frozenUsersList: action.payload.list ?? [], totalFrozenUsers: action.payload.totalCount, frozenUsers: action.payload.data.frozen_users,
                everyBuyUsers: action.payload.data.every_buy_users};
        }

        case FETCHING_ADMIN_INFO: {
            return {...state, fetchingAdminInfo: true};
        }
        case ADMIN_INFO_FETCHED: {

            return {...state, fetchingAdminInfo: false, selectedAdmin: action.payload.data.terminal_admin};
        }
        case ADMIN_INFO_FAILED: {
            return {...state, fetchingAdminInfo: false};

        }

        case ADDING_NEW_ADMIN: {
            return {...state, addingAdmin: true};
        }
        case NEW_ADMIN_ADD_FAILED: {
            return {...state, addingAdmin: false};
        }
        case NEW_ADMIN_ADDED: {
            return {...state, addingAdmin: false, addAdminOpened: false, redrawTerminalAdmins: true, backToFirstPage: true, };
        }

        case UPDATING_ADMIN_INFO: {
            return {...state, updatingAdminInfo: true};
        }
        case ADMIN_INFO_UPDATE_FAILED: {
            return {...state, updatingAdminInfo: false};
        }
        case ADMIN_INFO_UPDATED: {
            return {...state, updatingAdminInfo: false, adminEditOpened: false, redrawTerminalAdmins: true};
        }

        case UPDATING_TICKET_SIGNATURE: {
            return {...state, updatingTicketSignature: true};
        }
        case TICKET_SIGNATURE_UPDATED: {
            return {...state, updatingTicketSignature: false};
        }
        case TICKET_SIGNATURE_UPDATE_FAILED: {
            return {...state, updatingTicketSignature: false};
        }

        case UPDATE_TERMINAL_ADMINS_FILTER: {
            return {...state, redrawTerminalAdmins: true};
        }

        case DELETING_ADMIN: {
            return {...state, deletingAdmin: true};
        }
        case ADMIN_DELETE_FAILED: {
            return {...state, deletingAdmin: false};
        }
        case ADMIN_DELETED: {
            return {...state, deletingAdmin: false, redrawTerminalAdmins: true};
        }

        case SWITCH_ADMIN_INFO_DIALOG: {
            return {...state, adminInfoOpened: action.payload.state, selectedAdmin: action.payload.data.terminal_admin ?? {}, };
        }
        case SWITCH_ADMIN_EDIT_DIALOG: {
            return {...state, adminEditOpened: action.payload.state, selectedAdmin: action.payload.data.terminal_admin ?? {}, };
        }
        case SWITCH_ADD_ADMIN_DIALOG: {
            return {...state, addAdminOpened: action.payload.state, selectedAdmin: action.payload.data.terminal_admin ?? {}, };
        }
        case SWITCH_USERS_DIALOG: {
            console.log('switch:', action.payload);
            return {...state, usersDialogOpened: action.payload.state, settingsTerminalID: action.payload.terminal_id};
        }

        // case REDRAW_ADMINS_LIST: {
        //     return {...state, redrawTerminalAdmins: true, smoothMode: (action.payload ? !!action.payload.smoothMode : false)};
        // }


        default:
            return state;
    }
}

