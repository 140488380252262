import {
    CARDS_LIST_FETCHED,
    DAILY_PROFIT_DETAIL_LIST_FAILED,
    DAILY_PROFIT_DETAIL_LIST_FETCHED,
    DAILY_PROFITS_LIST_FAILED,
    DAILY_PROFITS_LIST_FETCHED,
    DETAIL_INFO_EXPANDED,
    FETCHING_CARDS_LIST,
    FETCHING_DAILY_PROFIT_DETAIL_LIST,
    FETCHING_DAILY_PROFITS_LIST,
    FETCHING_MASTER_PROFITS_LIST,
    FETCHING_MASTERS_DAILY_PROFIT_LIST,
    FETCHING_MASTERS_DAILY_PROFITS_LIST, FETCHING_MASTERS_PROFIT_DETAIL_LIST,
    MASTER_PROFITS_LIST_FAILED,
    MASTER_PROFITS_LIST_FETCHED,
    MASTERS_DAILY_PROFIT_LIST_FAILED,
    MASTERS_DAILY_PROFIT_LIST_FETCHED, MASTERS_DAILY_PROFITS_LIST_FAILED,
    MASTERS_DAILY_PROFITS_LIST_FETCHED, MASTERS_PROFIT_DETAIL_LIST_FAILED, MASTERS_PROFIT_DETAIL_LIST_FETCHED,


} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    masterProfits: [],
    totalMasterProfits: 0,
    loadingMasterProfitsList: false,
    redraw: false,
    backToFirstPage: false,
    loadingDailyProfitsList: false,
    redrawDailyProfitsList: false,
    dailyProfits: [],
    totalDailyProfits: 0,
    loadingProfitDetailList: false,
    dailyProfitDetails: [],
    totalDailyProfitDetails: 0,
    isExpanded: false,
    redrawDetailList: false,
    loadingMastersProfitDetailList: false,
    redrawMastersDetailList: false,
    mastersDailyProfitDetails: [],
    totalMastersDailyProfitDetails: 0,
    loadingMastersDailyProfitsList: false,
    redrawDailyMasters: false,
    dailyMasterProfits: [],
    totalDailyMasterProfits: 0,
    loadingMasterProfitDetailList: false,
    redrawMasterProfitDetailList: false,
    masterProfitDetails: [],
    totalMasterProfitDetails: 0


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_MASTER_PROFITS_LIST: {
            return {...state, loadingMasterProfitsList: true, redraw: false};
        }
        case MASTER_PROFITS_LIST_FETCHED: {
            return {...state, loadingMasterProfitsList: false, redraw: false,  backToFirstPage: false, masterProfits: action.payload.list ?? [], totalMasterProfits: action.payload.totalMasterProfits};
        }
        case MASTER_PROFITS_LIST_FAILED: {
            return {...state, loadingMasterProfitsList: false, backToFirstPage: false, };
        }
        case FETCHING_MASTERS_DAILY_PROFITS_LIST: {
            return {...state, loadingMastersDailyProfitsList: true, redrawDailyMasters: false};
        }
        case MASTERS_DAILY_PROFITS_LIST_FETCHED: {
            return {...state, loadingMastersDailyProfitsList: false, redrawDailyMasters: false, backToFirstPage: false, dailyMasterProfits: action.payload.list ?? [], totalDailyMasterProfits: action.payload.totalDailyMasterProfits};
        }
        case MASTERS_DAILY_PROFITS_LIST_FAILED: {
            return {...state, loadingMastersDailyProfitsList: false, backToFirstPage: false, };
        }
        case FETCHING_DAILY_PROFIT_DETAIL_LIST: {
            return {...state, loadingProfitDetailList: true, redrawDetailList: false};
        }
        case DAILY_PROFIT_DETAIL_LIST_FETCHED: {
            return {...state, loadingProfitDetailList: false, redrawDetailList: false, backToFirstPage: false, dailyProfitDetails: action.payload.list ?? [], totalDailyProfitDetails: action.payload.totalDailyProfitDetails};
        }
        case DAILY_PROFIT_DETAIL_LIST_FAILED: {
            return {...state, loadingProfitDetailList: false, backToFirstPage: false, };
        }
        case FETCHING_MASTERS_DAILY_PROFIT_LIST: {
            return {...state, loadingMastersProfitDetailList: true, redrawMastersDetailList: false};
        }
        case MASTERS_DAILY_PROFIT_LIST_FETCHED: {
            return {...state, loadingMastersProfitDetailList: false, redrawMastersDetailList: false, backToFirstPage: false, mastersDailyProfitDetails: action.payload.list ?? [], totalMastersDailyProfitDetails: action.payload.totalMastersDailyProfitDetails
            };
        }
        case MASTERS_DAILY_PROFIT_LIST_FAILED: {
            return {...state, loadingMastersProfitDetailList: false};
        }
        case FETCHING_DAILY_PROFITS_LIST: {
            return {...state, loadingDailyProfitsList: true, redrawDailyProfitsList: false};
        }
        case DAILY_PROFITS_LIST_FETCHED: {
            return {...state, loadingDailyProfitsList: false, redrawDailyProfitsList: false, backToFirstPage: false, dailyProfits: action.payload.list ?? [], totalDailyProfits: action.payload.totalDailyProfits};
        }
        case DAILY_PROFITS_LIST_FAILED: {
            return {...state, loadingDailyProfitsList: false};
        }
        case DETAIL_INFO_EXPANDED: {
            return {...state, isExpanded: true}
        }
        case FETCHING_MASTERS_PROFIT_DETAIL_LIST: {
            return {...state, loadingMasterProfitDetailList: true, redrawMasterProfitDetailList: false};
        }
        case MASTERS_PROFIT_DETAIL_LIST_FETCHED: {
            return {...state, loadingMasterProfitDetailList: false, redrawMasterProfitDetailList: false, backToFirstPage: false, masterProfitDetails: action.payload.list ?? [], totalMasterProfitDetails: action.payload.totalMasterProfitDetails};
        }
        case MASTERS_PROFIT_DETAIL_LIST_FAILED: {
            return {...state, loadingMasterProfitDetailList: false};
        }
        default:
            return state;
    }
}


