 import {
    AVAIL_CURRENCIES_FETCHED,
    CURRENCIES_FETCHED,
    CURRENCIES_LIST_FETCHED,
    CURRENCY_INFO_FAILED,
    CURRENCY_INFO_FETCHED,
    FETCHING_CURRENCIES_LIST,
    FETCHING_CURRENCY_INFO,
    FETCHING_NETWORKS_LIST,
    FETCHING_ORDER_INFO,
    FETCHING_ORDERS_LIST,
    NETWORKS_LIST_FAILED,
    NETWORKS_LIST_FETCHED,
    ORDER_INFO_FAILED,
    ORDER_INFO_FETCHED,
    ORDERS_LIST_FETCHED,
    PRICE_UPDATE_SETTINGS_FETCHED,
    TRANSLATES_FETCHED,
    SUPPLIES_FETCHED,
    SWITCH_ADD_CURRENCY_DIALOG,
    SWITCH_CARD_DOC_DIALOG,
    BASE_CURRENCIES_FETCHED,
    SWITCH_ORDER_DOC_DIALOG,
    ADDING_NEW_USER,
    NEW_USER_ADD_FAILED,
    NEW_USER_ADDED,
    NEW_CURRENCY_ADDED,
    NEW_CURRENCY_ADD_FAILED,
    ADDING_NEW_CURRENCY,
    FETCHING_GATEWAY_INFO,
    GATEWAY_INFO_FETCHED,
    GATEWAY_INFO_FAILED,
    UPDATING_GATEWAY_INFO,
    GATEWAY_INFO_UPDATE_FAILED,
    GATEWAY_INFO_UPDATED,
    FETCHING_TERMINAL_CURRENCY_INFO,
    TERMINAL_CURRENCY_INFO_FETCHED,
    TERMINAL_CURRENCY_INFO_FAILED,
    UPDATING_TERMINAL_CURRENCY_INFO,
    TERMINAL_CURRENCY_INFO_UPDATE_FAILED,
    TERMINAL_CURRENCY_INFO_UPDATED,
    SWITCH_GATEWAY_EDIT_DIALOG,
    SWITCH_TERMINAL_CURRENCY_EDIT_DIALOG,
    CHECKING_SOCKET,
    SOCKET_CHECKED,
    SOCKET_CHECK_FAILED,
    SOCKET_STOP_FAILED,
    STOPPING_SOCKET,
    SOCKET_STOPPED,
    STARTING_SOCKET,
    SOCKET_STARTED,
    SOCKET_START_FAILED,
    SWITCH_CARD_INFO_DIALOG,
    SWITCH_NETWORK_INFO_DIALOG,
    FETCHING_CARD_INFO,
    CARD_INFO_FETCHED,
    CARD_INFO_FAILED,
    FETCHING_NETWORK_INFO,
    NETWORK_INFO_FETCHED, NETWORK_INFO_FAILED, SWITCH_TERMINAL
 } from "../../@jumbo/constants/ActionTypes";
import {global} from "../store/global";

const INIT_STATE = {
    availableCurrencies: [],
    baseCurrencies: {},
    currencies: {},
    supplies: {},
    translates: {},
    totalCurrencies: 0,
    initialAmount: 0,
    loadingCurrencies: true,
    loadingBaseCurrencies: true,
    loadingAvailableCurrencies: true,
    loadingSupplies: true,
    selectedCurrency: '',
    selectedBase: '',
    selectedOrder: {},
    fetchingNetworks: false,
    networks: [],
    gateway: {},
    gatewayUsed: false,
    priceUpdatePeriod: 30000,
    lastPriceUpdateTime: 0,
    redraw: false,
    backToFirstPage: false,
    addCurrencyDialogOpened: false,
    addingCurrency: false,
    fetchingTerminalCurrency: false,
    selectedTerminalCurrency: {},
    updatingTerminalCurrency: false,
    terminalCurrencyEditOpened: false,
    socketRunning: null,
    switchingSocket: false,
    selectedNetwork: {},
    networkInfoOpened: false,
    fetchingNetwork: false,
    selectedTerminal: 1

};



export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRICE_UPDATE_SETTINGS_FETCHED: {
            global.priceUpdatePeriod = action.payload.priceUpdatePeriod;
            global.lastPriceUpdateTime = action.payload.lastEmitTime;
            return {...state, priceUpdatePeriod: action.payload.priceUpdatePeriod, lastPriceUpdateTime: action.payload.lastEmitTime};
        }
        case FETCHING_TERMINAL_CURRENCY_INFO: {
            return {...state, fetchingTerminalCurrency: true};
        }
        case TERMINAL_CURRENCY_INFO_FETCHED: {
            return {...state, fetchingTerminalCurrency: false, selectedTerminalCurrency: action.payload.data.currency};
        }
        case TERMINAL_CURRENCY_INFO_FAILED: {
            return {...state, fetchingTerminalCurrency: false};
        }
        case UPDATING_TERMINAL_CURRENCY_INFO: {
            return {...state, updatingTerminalCurrency: true};
        }
        case TERMINAL_CURRENCY_INFO_UPDATE_FAILED: {
            return {...state, updatingTerminalCurrency: false};
        }
        case TERMINAL_CURRENCY_INFO_UPDATED: {
            return {...state, updatingTerminalCurrency: false, terminalCurrencyEditOpened: false, redraw: true};
        }
        case SWITCH_TERMINAL_CURRENCY_EDIT_DIALOG: {
            return {...state, terminalCurrencyEditOpened: action.payload.state, selectedTerminalCurrency: action.payload.data ?? {}, };
        }
        case BASE_CURRENCIES_FETCHED: {
            return {...state, loadingBaseCurrencies: false, baseCurrencies: action.payload};
        }
        case CURRENCIES_FETCHED: {
            return {...state, loadingCurrencies: false,redraw: false, currencies: Object.assign(state.currencies, action.payload), totalCurrencies: action.payload.totalCount};
        }
        case TRANSLATES_FETCHED: {
            return {...state, translates: action.payload.translates};
        }
        case AVAIL_CURRENCIES_FETCHED: {
            return {...state, loadingAvailableCurrencies: false, availableCurrencies: action.payload};
        }
        case SUPPLIES_FETCHED: {
            return {...state, loadingSupplies: false, supplies: action.payload};
        }
        case FETCHING_NETWORKS_LIST: {
            return {...state, fetchingNetworks: true};
        }
        case NETWORKS_LIST_FETCHED: {
            return {...state, fetchingNetworks: false, networks: action.payload.list ?? []};
        }
        case NETWORKS_LIST_FAILED: {
            return {...state, fetchingNetworks: false};
        }
        case FETCHING_CURRENCIES_LIST: {
            return {...state, loadingOrders: !state.smoothMode, redraw: false};
        }
        case CURRENCIES_LIST_FETCHED: {
            return {...state, loadingOrders: false, redraw: false, backToFirstPage: false, smoothMode: false, orders: action.payload.list ?? [], totalOrders: action.payload.totalCount};
        }
        case FETCHING_CURRENCY_INFO: {
            return {...state, fetchingOrder: true};
        }
        case CURRENCY_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_card_verified: user.national_card_verified,
                selfie_verified: user.selfie_verified,
            };
            global.selectedOrderID = action.payload.data.factor.id;
            return {...state, fetchingOrder: false, selectedOrder: action.payload.data.factor, payments: action.payload.data.payments,
                walletTransfers: action.payload.data.wallet_transfers, orderUser: user};
        }
        case CURRENCY_INFO_FAILED: {
            return {...state, fetchingOrder: false};

        }
        case SWITCH_ADD_CURRENCY_DIALOG: {
            console.log("addCurrencyDialog Reducer")
            return {...state, addCurrencyDialogOpened: action.payload.state};
        }
        case ADDING_NEW_CURRENCY: {
            return {...state, addingCurrency: true};
        }
        case NEW_CURRENCY_ADD_FAILED: {
            return {...state, addingCurrency: false};
        }
        case NEW_CURRENCY_ADDED: {
            return {...state, addingCurrency: false, addCurrencyDialogOpened: false};
        }
        case CHECKING_SOCKET: {
            return {...state, socketRunning: null}
        }
        case SOCKET_CHECKED: {
            return {...state, socketRunning: action.payload.data.status}
        }
        case STOPPING_SOCKET: {
            return {...state, switchingSocket: true}
        }
        case SOCKET_STOPPED: {
            return {...state, switchingSocket: false, socketRunning: false}
        }
        case SOCKET_STOP_FAILED: {
            return {...state, switchingSocket: false}
        }
        case STARTING_SOCKET: {
            return {...state, switchingSocket: true}
        }
        case SOCKET_STARTED: {
            return {...state, switchingSocket: false, socketRunning: true}
        }
        case SOCKET_START_FAILED: {
            return {...state, switchingSocket: false}
        }
        case SWITCH_NETWORK_INFO_DIALOG: {
            global.selectedCardID = null;
            return {...state, networkInfoOpened: action.payload.state, selectedNetwork: action.payload.data ?? {}, };
        }
        case SWITCH_TERMINAL: {
            console.log("switchTerminal reducer ")
            return {...state, selectedTerminal: action.payload.data ?? {}}
        }
        case FETCHING_NETWORK_INFO: {
            return {...state, fetchingNetwork: true};
        }
        case NETWORK_INFO_FETCHED: {
            // console.log('Reducer data: ', action.payload.data);
            // const user = action.payload.data.user;
            // user.auth_status = {
            //     email_verified: user.email_verified,
            //     phone_number_verified: user.phone_number_verified,
            //     national_card_verified: user.national_card_verified,
            //     selfie_verified: user.selfie_verified,
            // };
            global.selectedCardID = action.payload.data.network.id;
            return {...state, fetchingNetwork: false, selectedNetwork: action.payload.data.network};
        }
        case NETWORK_INFO_FAILED: {
            global.selectedCardID = null;
            return {...state, fetchingNetwork: false};

        }
        default:
            return state;
    }
}