import AudioManager from "./AudioManager";


const AlarmManager = {
    authorizationRequest: () => AudioManager.playAuthorizationRequest(),
    newCard: () => AudioManager.playNewCard(),
    newMessage: (isSelected) => AudioManager.playNewMessage(isSelected),
    newTicket: () => AudioManager.playNewTicket(),
    waitingOrder: () => AudioManager.playWaitingOrder(),
    welcomeBack: () => AudioManager.playWelcomeBack(),
    newWithdraw: () => AudioManager.playNewWithdraw(),
    messageSent: () => AudioManager.playMessageSent(),
    offlineDeposit: () => AudioManager.playOfflineDeposit(),

}

export default AlarmManager;