import {
    ADDING_NEW_CURRENCY,
    AVAIL_CURRENCIES_FETCHED,
    BASE_CURRENCIES_FETCHED,
    CHECKING_SOCKET,
    CURRENCIES_FETCHED,
    CURRENCIES_LIST_FAILED,
    CURRENCIES_LIST_FETCHED,
    CURRENCY_INFO_FAILED,
    CURRENCY_INFO_FETCHED,
    FETCHING_CURRENCIES_LIST,
    FETCHING_CURRENCY_INFO,
    FETCHING_NETWORK_INFO,
    FETCHING_NETWORKS_LIST,
    FETCHING_TERMINAL_CURRENCY_INFO,
    NETWORK_INFO_FAILED, NETWORK_INFO_FETCHED,
    NETWORKS_LIST_FAILED,
    NETWORKS_LIST_FETCHED,
    NEW_CURRENCY_ADD_FAILED,
    NEW_CURRENCY_ADDED,
    SOCKET_CHECK_FAILED,
    SOCKET_CHECKED, SOCKET_START_FAILED, SOCKET_STARTED,
    SOCKET_STOP_FAILED,
    SOCKET_STOPPED,
    STARTING_SOCKET,
    STOPPING_SOCKET,
    SUPPLIES_FETCHED,
    SWITCH_ADD_CURRENCY_DIALOG,
    SWITCH_NETWORK_INFO_DIALOG, SWITCH_TERMINAL,
    SWITCH_TERMINAL_CURRENCY_EDIT_DIALOG,
    TERMINAL_CURRENCY_INFO_FAILED,
    TERMINAL_CURRENCY_INFO_FETCHED,
    TERMINAL_CURRENCY_INFO_UPDATE_FAILED,
    TERMINAL_CURRENCY_INFO_UPDATED,
    TRANSLATES_FETCHED,
    UPDATING_TERMINAL_CURRENCY_INFO
} from "../../@jumbo/constants/ActionTypes";
import {dispatchAction, fetchList, fetchOne, MULTIPART_HEADER, updateOne} from "../../@jumbo/utils/Api";
import {
    CURRENCY_MANAGEMENT,
} from "../../@jumbo/constants/Units";


export const getTerminalCurrency = (id) => {
    return fetchOne({
        url: 'currencies/' + id,
        params: {},
        fetchingAction: FETCHING_TERMINAL_CURRENCY_INFO,
        successAction: TERMINAL_CURRENCY_INFO_FETCHED,
        failAction: TERMINAL_CURRENCY_INFO_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};

export const updateTerminalCurrency = (id, params) => {
    return updateOne({
        url: 'currencies/' + id,
        params: params,
        fetchingAction: UPDATING_TERMINAL_CURRENCY_INFO,
        successAction: TERMINAL_CURRENCY_INFO_UPDATED,
        failAction: TERMINAL_CURRENCY_INFO_UPDATE_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};

export const switchEditDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_TERMINAL_CURRENCY_EDIT_DIALOG, payload: {state: state, data: info}});
    }
};

export const setCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: CURRENCIES_FETCHED, payload: currencies});
    }
    // customDispatch();

};

export const setAvailCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: AVAIL_CURRENCIES_FETCHED, payload: currencies});
    }
};

export const setBaseCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: BASE_CURRENCIES_FETCHED, payload: currencies});
    }
};

export const setTranslates = (translates) => {
    if(!translates)
        return;
    return (dispatch) => {
        dispatch({type: TRANSLATES_FETCHED, payload: {translates: translates}});
    }
};

export const setSupplies = (supplies) => {
    if(!supplies)
        return;
    return (dispatch) => {
        dispatch({type: SUPPLIES_FETCHED, payload: supplies});
    }
    // customDispatch();

};

export const getNetworks = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'networks/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_NETWORKS_LIST,
        successAction: NETWORKS_LIST_FETCHED,
        failAction: NETWORKS_LIST_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })

};

export const addCurrency = (id, params) => {
    return dispatchAction({
        url: '/currencies/new_pair',
        params: params,
        fetchingAction: ADDING_NEW_CURRENCY,
        successAction: NEW_CURRENCY_ADDED,
        failAction: NEW_CURRENCY_ADD_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};
export const switchAddCurrencyDialog = (state, info = {}) => {
    console.log("addCurrencyDialog action")
    return (dispatch) => {
        dispatch({type: SWITCH_ADD_CURRENCY_DIALOG, payload: {state: state, data: info}});
    }
};

export const getCurrenciesList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'orders/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_CURRENCIES_LIST,
        successAction: CURRENCIES_LIST_FETCHED,
        failAction: CURRENCIES_LIST_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })

};

export const getCurrency = (id) => {
    return fetchOne({
        url: 'orders/' + id,
        params: {},
        fetchingAction: FETCHING_CURRENCY_INFO,
        successAction: CURRENCY_INFO_FETCHED,
        failAction: CURRENCY_INFO_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};


export const checkSocket = () => {
    return dispatchAction({
        url: 'currencies/socket/check_socket/',
        params: {},
        fetchingAction: CHECKING_SOCKET,
        successAction: SOCKET_CHECKED,
        failAction: SOCKET_CHECK_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};
export const stopSocket = () => {
    return dispatchAction({
        url: 'currencies/socket/stop_socket/',
        params: {},
        fetchingAction: STOPPING_SOCKET,
        successAction: SOCKET_STOPPED,
        failAction: SOCKET_STOP_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};
export const startSocket = () => {
    return dispatchAction({
        url: 'currencies/socket/start_socket/',
        params: {},
        fetchingAction: STARTING_SOCKET,
        successAction: SOCKET_STARTED,
        failAction: SOCKET_START_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};
export const switchNetworkInfoDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_NETWORK_INFO_DIALOG, payload: {state: state, data: info}});
    }
};
export const getNetwork = (id) => {
    return fetchOne({
        url: 'networks/' + id,
        params: {},
        fetchingAction: FETCHING_NETWORK_INFO,
        successAction: NETWORK_INFO_FETCHED,
        failAction: NETWORK_INFO_FAILED,
        unitName: CURRENCY_MANAGEMENT,
        showError: true
    })
};
export const switchTerminal = (info = {}) => {
    console.log("switchTerminal action ", info)
    return (dispatch) => {
        dispatch({type: SWITCH_TERMINAL, payload: { data: info}})
    }
};
