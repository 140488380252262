import {
    PRIZE_INFO_FAILED,
    UPDATING_PRIZE_INFO,
    PRIZE_INFO_UPDATE_FAILED,
    PRIZE_INFO_UPDATED,
    UPDATE_PRIZES_FILTER,
    SWITCH_PRIZE_INFO_DIALOG,
    SWITCH_PRIZE_EDIT_DIALOG,
    REDRAW_PRIZES_LIST,
    FETCHING_PRIZES_LIST, PRIZES_LIST_FETCHED, FETCHING_PRIZE_INFO, PRIZE_INFO_FETCHED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    prizes: [],
    totalPrizes: 0,
    loadingPrizes: false,
    fetchingPrize: false,
    updatingPrize: false,
    selectedPrize: {},
    prizeInfoOpened: false,
    prizeEditOpened: false,
    redraw: false,
    backToFirstPage: false,
    gateway: {},


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_PRIZES_LIST: {
            return {...state, loadingPrizes: true, redraw: false};
        }
        case PRIZES_LIST_FETCHED: {
            return {...state, loadingPrizes: false, redraw: false, backToFirstPage: false, prizes: action.payload.list ?? [], totalPrizes: action.payload.totalCount};
        }
        case FETCHING_PRIZE_INFO: {
            return {...state, fetchingPrize: true};
        }
        case PRIZE_INFO_FETCHED: {

            return {...state, fetchingPrize: false, selectedPrize: action.payload.data.prize};
        }
        case PRIZE_INFO_FAILED: {
            return {...state, fetchingPrize: false};

        }
        case UPDATING_PRIZE_INFO: {
            return {...state, updatingPrize: true};
        }
        case PRIZE_INFO_UPDATE_FAILED: {
            return {...state, updatingPrize: false};
        }
        case PRIZE_INFO_UPDATED: {
            return {...state, updatingPrize: false, prizeEditOpened: false, redraw: true};
        }
        case UPDATE_PRIZES_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case SWITCH_PRIZE_INFO_DIALOG: {
            return {...state, prizeInfoOpened: action.payload.state, selectedPrize: action.payload.data ?? {}, };
        }
        case SWITCH_PRIZE_EDIT_DIALOG: {
            return {...state, prizeEditOpened: action.payload.state, selectedPrize: action.payload.data ?? {}, };
        }
        case REDRAW_PRIZES_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

