import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const TerminalManagement = ({ match }) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                {/*<Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/all_orders`} />*/}
                <Route path={`${requestedUrl}/settings`} component={lazy(() => import('./Settings'))} />
                <Route path={`${requestedUrl}/admins`} component={lazy(() => import('./Admins'))} />
                <Route path={`${requestedUrl}/levels`} component={lazy(() => import('./Levels'))} />
            </Switch>
        </Suspense>
    );
};

export default TerminalManagement;
