import {
    SUPPLY_SETTINGS_LIST_FETCHED,
    FETCHING_SUPPLY_SETTINGS_LIST,
    REDRAW_SUPPLY_SETTINGS_LIST,
    FETCHING_SUPPLY_SETTING_INFO,
    SUPPLY_SETTING_INFO_FETCHED,
    SUPPLY_SETTING_INFO_FAILED,
    SWITCH_SUPPLY_SETTING_INFO_DIALOG,
    SWITCH_SUPPLY_SETTING_EDIT_DIALOG,
    UPDATING_SUPPLY_SETTING_INFO,
    SUPPLY_SETTING_INFO_UPDATED,
    SUPPLY_SETTING_INFO_UPDATE_FAILED,
    DELETING_SUPPLY_SETTING,
    SUPPLY_SETTING_DELETED,
    SUPPLY_SETTING_DELETE_FAILED,
    SWITCH_SUPPLY_SETTING_DOC_DIALOG,
    AUTHORIZING_SUPPLY_SETTING,
    SUPPLY_SETTING_AUTHORIZED,
    SUPPLY_SETTING_AUTHORIZATION_FAILED,
    UPDATE_SUPPLY_SETTINGS_FILTER,
    FETCHING_SPECIAL_SUPPLIES_LIST,
    SPECIAL_SUPPLIES_LIST_FETCHED,
    FETCHING_SPECIAL_SUPPLY_INFO,
    ORDER_INFO_FETCHED,
    SPECIAL_SUPPLY_INFO_FAILED,
    SWITCH_ORDER_INFO_DIALOG,
    FETCHING_SUPPLY_TRANSACTIONS_LIST,
    SUPPLY_TRANSACTIONS_LIST_FETCHED,
    FETCHING_SUPPLIES_LIST,
    SUPPLIES_LIST_FETCHED,
    UPDATING_SUPPLIES_INFO,
    SUPPLIES_INFO_UPDATE_FAILED, SUPPLIES_INFO_UPDATED, SWITCH_SUPPLIES_EDIT_DIALOG

} from '../../@jumbo/constants/ActionTypes';
import {global} from "../store/global";

const INIT_STATE = {
    specialSupplies: [],
    supplySettings: [],
    supplies: [],
    supplyTransactions: [],
    totalSupplyTransactions: 0,
    totalSupplies: 0,
    totalSupplySettings: 0,
    totalSpecialSupplies: 0,
    loadingSupplyTransactions: false,
    loadingSupplies: false,
    loadingSupplySettings: false,
    loadingSpecialSupplies: false,
    fetchingSupplySetting: false,
    fetchingSpecialSupply: false,
    updatingSupplySetting: false,
    authorizingSupplySetting: false,
    selectedSupplySetting: {},
    selectedSpecialSupply:{},
    supplySettingPayments: [],
    supplySettingUser: {},
    supplySettingInfoOpened: false,
    supplySettingEditOpened: false,
    supplySettingAuthOpened: false,
    gatewayUsed: false,
    redraw: false,
    backToFirstPage: false,
    supplyTransactionsRedraw: false,
    specialSuppliesRedraw: false,
    suppliesRedraw: false,
    gateway: {},
    fetchingOrder: false,
    selectedOrder: {},
    payments: [],
    walletTransfers: [],
    orderUser: {},
    orderInfoOpened: false,
    suppliesEditOpened: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_SUPPLY_TRANSACTIONS_LIST: {
            return {...state, loadingSupplyTransactions: !state.smoothMode, supplyTransactionsRedraw: false};
        }
        case SUPPLY_TRANSACTIONS_LIST_FETCHED: {
            return {...state, loadingSupplyTransactions: false, supplyTransactionsRedraw: false, backToFirstPage: false, smoothMode: false, supplyTransactions: action.payload.list ?? [], totalSupplyTransactions: action.payload.totalCount};
        }
        case FETCHING_SUPPLIES_LIST: {
            return {...state, loadingSupplies: !state.smoothMode, suppliesRedraw: false};
        }
        case SUPPLIES_LIST_FETCHED: {
            return {...state, loadingSupplies: false, suppliesRedraw: false, backToFirstPage: false, smoothMode: false, supplies: action.payload.list ?? [], totalSupplies: action.payload.totalCount};
        }

        case FETCHING_SPECIAL_SUPPLIES_LIST: {
            return {...state, loadingSpecialSupplies: !state.smoothMode, specialSuppliesRedraw: false};
        }
        case SPECIAL_SUPPLIES_LIST_FETCHED: {
            return {...state, loadingSpecialSupplies: false, specialSuppliesRedraw: false, backToFirstPage: false, smoothMode: false, specialSupplies: action.payload.list ?? [], totalSpecialSupplies: action.payload.totalCount};
        }

        case FETCHING_SPECIAL_SUPPLY_INFO: {
            return {...state, fetchingSpecialSupply: true};
        }

        case SWITCH_ORDER_INFO_DIALOG: {
            global.selectedOrderID = null;
            return {...state, orderInfoOpened: action.payload.state, selectedOrder: action.payload.data ?? {}, };
        }

        case ORDER_INFO_FETCHED: {
            console.log('Reducer data: ', action.payload.data);
            const user = action.payload.data.user;
            // user.auth_status = {
            //     email_verified: user.email_verified,
            //     phone_number_verified: user.phone_number_verified,
            //     national_card_verified: user.national_card_verified,
            //     selfie_verified: user.selfie_verified,
            // };
            global.selectedOrderID = action.payload.data.factor.id;
            return {...state, fetchingOrder: false, selectedOrder: action.payload.data.factor, payments: action.payload.data.payments,
                walletTransfers: action.payload.data.wallet_transfers, orderUser: user};
        }

        case SPECIAL_SUPPLY_INFO_FAILED: {
            return {...state, fetchingSpecialSupply: false};

        }

        case FETCHING_SUPPLY_SETTINGS_LIST: {
            return {...state, loadingSupplySettings: true, redraw: false};
        }
        case SUPPLY_SETTINGS_LIST_FETCHED: {
            return {...state, loadingSupplySettings: false, redraw: false, backToFirstPage: false, supplySettings: action.payload.list ?? [], totalSupplySettings: action.payload.totalCount};
        }
        case FETCHING_SUPPLY_SETTING_INFO: {
            return {...state, fetchingSupplySetting: true};
        }
        case SUPPLY_SETTING_INFO_FETCHED: {
            const user = action.payload.data.user;
            user.auth_status = {
                email_verified: user.email_verified,
                phone_number_verified: user.phone_number_verified,
                national_setting_verified: user.national_setting_verified,
                selfie_verified: user.selfie_verified,
            };
            return {...state, fetchingSupplySetting: false, selectedSupplySetting: action.payload.data.setting, supplySettingPayments: action.payload.data.payments, supplySettingUser: user};
        }
        case SUPPLY_SETTING_INFO_FAILED: {
            return {...state, fetchingSupplySetting: false};

        }
        case UPDATING_SUPPLY_SETTING_INFO: {
            return {...state, updatingSupplySetting: true};
        }
        case SUPPLY_SETTING_INFO_UPDATE_FAILED: {
            return {...state, updatingSupplySetting: false};
        }
        case SUPPLY_SETTING_INFO_UPDATED: {
            return {...state, updatingSupplySetting: false, supplySettingEditOpened: false, redraw: true};
        }
        case UPDATING_SUPPLIES_INFO: {
            return {...state, updatingSupplies: true};
        }
        case SUPPLIES_INFO_UPDATE_FAILED: {
            return {...state, updatingSupplies: false};
        }
        case SUPPLIES_INFO_UPDATED: {
            return {...state, updatingSupplies: false, suppliesEditOpened: false, supplies: action.payload.data.supplies};
        }
        case AUTHORIZING_SUPPLY_SETTING: {
            return {...state, authorizingSupplySetting: true};
        }
        case SUPPLY_SETTING_AUTHORIZATION_FAILED: {
            return {...state, authorizingSupplySetting: false, supplySettingAuthOpened: false, redraw: true};
        }
        case SUPPLY_SETTING_AUTHORIZED: {
            return {...state, authorizingSupplySetting: false};
        }
        case UPDATE_SUPPLY_SETTINGS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_SUPPLY_SETTING: {
            return {...state, deletingSetting: true};
        }
        case SUPPLY_SETTING_DELETE_FAILED: {
            return {...state, deletingSetting: false};
        }
        case SUPPLY_SETTING_DELETED: {
            return {...state, deletingSetting: false, redraw: true};
        }

        case SWITCH_SUPPLY_SETTING_INFO_DIALOG: {
            return {...state, supplySettingInfoOpened: action.payload.state, selectedSupplySetting: action.payload.data ?? {}, };
        }
        case SWITCH_SUPPLY_SETTING_EDIT_DIALOG: {
            return {...state, supplySettingEditOpened: action.payload.state, selectedSupplySetting: action.payload.data ?? {}, };
        }
         case SWITCH_SUPPLIES_EDIT_DIALOG: {
            return {...state, suppliesEditOpened: action.payload.state, };
        }
        case SWITCH_SUPPLY_SETTING_DOC_DIALOG: {
            return {...state, supplySettingAuthOpened: action.payload.state, selectedSupplySetting: action.payload.data ?? {}, };
        }
        case REDRAW_SUPPLY_SETTINGS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

