import {
    ALL_PROFITS_LIST_FETCHED,
    CURRENCY_PROFITS_LIST_FETCHED,
    FETCHING_ALL_PROFITS_LIST,
    FETCHING_CURRENCY_PROFITS_LIST,
    FETCHING_LOSSES_LIST,
    FETCHING_PROFITS_LIST,
    FETCHING_WHOLE_CURRENCY_PROFITS_LIST,
    LOSSES_LIST_FETCHED,
    PROFIT_STATS_FETCHED,
    PROFITS_LIST_FETCHED,
    SIMPLE_STATS_FETCHED, UPDATE_ALL_PROFITS_FILTER, UPDATE_COSTS_FILTER,
    UPDATE_DAILY_PROFITS_FILTER,
    WHOLE_CURRENCY_PROFITS_LIST_FETCHED,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    stats: {},
    profitStats: {stats: []},
    rangedStats: {},
    profits: [],
    totalProfits: 0,
    loadingProfits: false,
    allProfits: [],
    totalAllProfits: 0,
    loadingAllProfits: false,
    fetchingProfit: false,
    losses: [],
    totalLosses: 0,
    loadingLosses: false,
    fetchingLoss: false,
    lossesRedraw: false,
    backToFirstPage: false,
    updatingProfit: false,
    authorizingProfit: false,
    selectedProfit: {},
    payments: [],
    walletTransfers: [],
    profitUser: {},
    profitInfoOpened: false,
    profitEditOpened: false,
    profitAuthOpened: false,
    gatewayUsed: false,
    redrawDaily: false,
    redrawCosts: false,
    redrawAllProfits: false,
    smoothMode: false,
    gateway: {},
    processingProfit: false,
    cancelingProfit: false,
    lockingProfit: false,
    requestBuyDialogOpened: false,
    withdrawDialogOpened: false,
    depositDialogOpened: false,
    newDepositDialogOpened: false,
    requestingNewWithdraw: false,
    addWithdrawReqOpened: false,
    requestSellDialogOpened: false,
    buyDocDialogOpened: false,
    sellDocDialogOpened: false,
    loadingCurrencyProfits: false,
    currencyProfitsRedraw: false,
    currencyProfits: [],
    totalCurrencyProfits: 0,
    loadingWholeCurrencyProfits: false,
    wholeCurrencyProfits: [],
    totalWholeCurrencyProfits: 0


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_ALL_PROFITS_LIST: {
            return {...state, loadingAllProfits: !state.smoothMode, redrawAllProfits: false};
        }
        case ALL_PROFITS_LIST_FETCHED: {
            return {...state, loadingAllProfits: false, redrawAllProfits: false, backToFirstPage: false, smoothMode: false, allProfits: action.payload.list ?? [],
                totalAllProfits: action.payload.totalCount, rangedStats: action.payload.data};
        }
        case FETCHING_PROFITS_LIST: {
            return {...state, loadingProfits: !state.smoothMode, redrawDaily: false};
        }
        case PROFITS_LIST_FETCHED: {
            return {...state, loadingProfits: false, redrawDaily: false, backToFirstPage: false, smoothMode: false, profits: action.payload.list ?? [],
                totalProfits: action.payload.totalCount, rangedStats: action.payload.data};
        }
        case FETCHING_LOSSES_LIST: {
            return {...state, loadingLosses: !state.smoothMode, redrawCosts: false};
        }
        case LOSSES_LIST_FETCHED: {
            return {...state, loadingLosses: false, redrawCosts: false, backToFirstPage: false, smoothMode: false, losses: action.payload.list ?? [],
                totalLosses: action.payload.totalCount, rangedStats: action.payload.data};
        }
        case FETCHING_CURRENCY_PROFITS_LIST: {
            return {...state, loadingCurrencyProfits: !state.smoothMode, currencyProfitsRedraw: false};
        }
        case CURRENCY_PROFITS_LIST_FETCHED: {
            return {...state, loadingCurrencyProfits: false, currencyProfitsRedraw: false, backToFirstPage: false, smoothMode: false, currencyProfits: action.payload.list ?? [],
                totalCurrencyProfits: action.payload.totalCount, rangedStats: action.payload.data};
        }
        case FETCHING_WHOLE_CURRENCY_PROFITS_LIST: {
            return {...state, loadingWholeCurrencyProfits: !state.smoothMode};
        }
        case WHOLE_CURRENCY_PROFITS_LIST_FETCHED: {
            return {...state, loadingWholeCurrencyProfits: false, smoothMode: false, backToFirstPage: false, wholeCurrencyProfits: action.payload.list ?? [],
                totalWholeCurrencyProfits: action.payload.totalCount, rangedStats: action.payload.data};
        }
        case SIMPLE_STATS_FETCHED: {
            return {...state, stats: action.payload.data}
        }
        case PROFIT_STATS_FETCHED: {
            return {...state, profitStats: action.payload.data}
        }

        case UPDATE_DAILY_PROFITS_FILTER: {
            return {...state, redrawDaily: true, backToFirstPage: true, };
        }
        case UPDATE_COSTS_FILTER: {
            return {...state, redrawCosts: true, backToFirstPage: true, };
        }
        case UPDATE_ALL_PROFITS_FILTER: {
            return {...state, redrawAllProfits: true, backToFirstPage: true, };
        }

        default:
            return state;
    }
}

