import {
    LOGS_LIST_FETCHED,
    FETCHING_LOGS_LIST,
    REDRAW_LOGS_LIST,
    LOGS_LIST_FAILED,
    FETCHING_LOG_INFO,
    LOG_INFO_FETCHED,
    LOG_INFO_FAILED,
    UPDATING_LOG_INFO,
    LOG_INFO_UPDATED,
    LOG_INFO_UPDATE_FAILED,
    DELETING_LOG,
    LOG_DELETED,
    LOG_DELETE_FAILED,
    UPDATE_LOGS_FILTER,


} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    logs: [],
    totalLogs: 0,
    loadingLogs: false,
    fetchingLog: false,
    updatingLog: false,
    authorizingLog: false,
    selectedLog: {},
    logPayments: [],
    logUser: {},
    logInfoOpened: false,
    logEditOpened: false,
    logAuthOpened: false,
    logUsed: false,
    redraw: false,
    backToFirstPage: false,
    log: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_LOGS_LIST: {
            return {...state, loadingLogs: true, redraw: false};
        }
        case LOGS_LIST_FETCHED: {
            return {...state, loadingLogs: false, redraw: false, backToFirstPage: false, logs: action.payload.list ?? [], totalLogs: action.payload.totalCount};
        }
        case FETCHING_LOG_INFO: {
            return {...state, fetchingLog: true};
        }
        case LOG_INFO_FETCHED: {
            let logs = state.logs;
            logs.some((log, index) => {
                if(log.id === action.payload.data.user_log.id){
                    logs[index].content = action.payload.data.user_log.content;
                    logs[index].error = action.payload.data.user_log.error;
                    logs[index].infoFetched = true;
                    return true;
                }
            });
            return {...state, fetchingLog: false, logs: logs};
        }
        case LOG_INFO_FAILED: {
            return {...state, fetchingLog: false};

        }
        case UPDATING_LOG_INFO: {
            return {...state, updatingLog: true};
        }
        case LOG_INFO_UPDATE_FAILED: {
            return {...state, updatingLog: false};
        }
        case LOG_INFO_UPDATED: {
            return {...state, updatingLog: false, logEditOpened: false, redraw: true};
        }
        case UPDATE_LOGS_FILTER: {
            return {...state, redraw: true, backToFirstPage: true, };
        }
        case DELETING_LOG: {
            return {...state, deletingLog: true};
        }
        case LOG_DELETE_FAILED: {
            return {...state, deletingLog: false};
        }
        case LOG_DELETED: {
            return {...state, deletingLog: false, redraw: true};
        }

        case REDRAW_LOGS_LIST: {
            return {...state, redraw: true};
        }
        default:
            return state;
    }
}

