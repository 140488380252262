
import {
    ORDERS_LIST_FETCHED,
    FETCHING_ORDERS_LIST,
    REDRAW_ORDERS_LIST,
    ORDERS_LIST_FAILED,
    FETCHING_ORDER_INFO,
    ORDER_INFO_FETCHED,
    ORDER_INFO_FAILED,
    SWITCH_ORDER_INFO_DIALOG,
    SWITCH_ORDER_EDIT_DIALOG,
    UPDATING_ORDER_INFO,
    ORDER_INFO_UPDATED,
    ORDER_INFO_UPDATE_FAILED,
    DELETING_ORDER,
    ORDER_DELETED,
    ORDER_DELETE_FAILED,
    SWITCH_ORDER_DOC_DIALOG,
    UPDATE_ORDERS_FILTER,
    SWITCH_WITHDRAW_DIALOG,
    PROCESSING_ORDER,
    ORDER_PROCESSED,
    ORDER_PROCESS_FAILED,
    CANCELING_ORDER,
    ORDER_CANCELED,
    ORDER_CANCEL_FAILED,
    BLOCKING_ORDER,
    ORDER_BLOCKED,
    ORDER_BLOCK_FAILED,
    LOCKING_ORDER,
    ORDER_LOCKED,
    ORDER_LOCK_FAILED,
    SWITCH_REQUEST_BUY_DIALOG,
    SWITCH_DEPOSIT_DIALOG,
    SWITCH_NEW_DEPOSIT_DIALOG,
    SUBMITTING_NEW_DEPOSIT,
    NEW_DEPOSIT_SUBMITTED,
    NEW_DEPOSIT_FAILED,
    SUBMITTING_NEW_BUY,
    NEW_BUY_SUBMITTED,
    NEW_SUBMIT_FAILED,
    SWITCH_REQUEST_SELL_DIALOG,
    SWITCH_BUY_DOCUMENT_DIALOG,
    SWITCH_SELL_DOCUMENT_DIALOG,
    SUBMITTING_NEW_SELL,
    NEW_SELL_SUBMITTED,
    NEW_SELL_SUBMIT_FAILED,
    SWITCH_UNFREEZE_DIALOG, UNFREEZING_ORDER, ORDER_UNFROZEN, ORDER_UNFREEZE_FAILED,
} from "../../@jumbo/constants/ActionTypes";

import {
    DELETE_ORDER, DOCUMENT_AUTHORIZATION,
    EDIT_ORDER,
    ORDER_MANAGEMENT,
} from "../../@jumbo/constants/Units";
import {deleteOne, dispatchAction, fetchList, fetchOne, updateOne} from "../../@jumbo/utils/Api";


export const deleteOrder = (id) => {
    return deleteOne({
        url: 'credit_cards/' + id,
        params: {},
        fetchingAction: DELETING_ORDER,
        successAction: ORDER_DELETED,
        failAction: ORDER_DELETE_FAILED,
        unitName: DELETE_ORDER,
        showError: true
    })
}

export const updateOrder = (id, params) => {
    return updateOne({
        url: 'credit_cards/' + id,
        params: params,
        fetchingAction: UPDATING_ORDER_INFO,
        successAction: ORDER_INFO_UPDATED,
        failAction: ORDER_INFO_UPDATE_FAILED,
        unitName: EDIT_ORDER,
        showError: true
    })
}

export const updateOrderNote = (id, params) => {
    return updateOne({
        url: 'orders/note/' + id,
        params: params,
        fetchingAction: UPDATING_ORDER_INFO,
        successAction: ORDER_INFO_UPDATED,
        failAction: ORDER_INFO_UPDATE_FAILED,
        unitName: ORDER_MANAGEMENT,
        showError: true
    })
}

export const getOrder = (id) => {
    return fetchOne({
        url: 'orders/' + id,
        params: {},
        fetchingAction: FETCHING_ORDER_INFO,
        successAction: ORDER_INFO_FETCHED,
        failAction: ORDER_INFO_FAILED,
        unitName: ORDER_MANAGEMENT,
        showError: true
    })
}

export const getOrdersList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'orders/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_ORDERS_LIST,
        successAction: ORDERS_LIST_FETCHED,
        failAction: ORDERS_LIST_FAILED,
        unitName: ORDER_MANAGEMENT,
        showError: true
    })

};

export const getDepositsList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'orders/deposits/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_ORDERS_LIST,
        successAction: ORDERS_LIST_FETCHED,
        failAction: ORDERS_LIST_FAILED,
        unitName: ORDER_MANAGEMENT,
        showError: true
    })

};

export const getWithdrawsList = ({type, page, count, filters, sort}) => {
    return fetchList({
        url: 'orders/withdraws/',
        params: {
            type: type,
            page: page,
            count: count,
            filters: filters,
            sort: sort
        },
        fetchingAction: FETCHING_ORDERS_LIST,
        successAction: ORDERS_LIST_FETCHED,
        failAction: ORDERS_LIST_FAILED,
        unitName: ORDER_MANAGEMENT,
        showError: true
    })

};

export const forceSell = (params) => {
    return dispatchAction({
        url: 'orders/force_sell',
        params: params,
        fetchingAction: SUBMITTING_NEW_SELL,
        successAction:  NEW_SELL_SUBMITTED,
        failAction:     NEW_SELL_SUBMIT_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {

        }
    })
}

export const processSell = (transferID, params) => {
    return dispatchAction({
        url: 'orders/sell/process/' + transferID,
        params: params,
        fetchingAction: PROCESSING_ORDER,
        successAction:  ORDER_PROCESSED,
        failAction:     ORDER_PROCESS_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        }
    })
}

export const cancelSell = (transferID, params) => {
    return dispatchAction({
        url: 'orders/sell/cancel/' + transferID,
        params: params,
        fetchingAction: CANCELING_ORDER,
        successAction:  ORDER_CANCELED,
        failAction:     ORDER_CANCEL_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {

        },
    })
}

export const forceBuy = (params) => {
    return dispatchAction({
        url: 'orders/force_buy',
        params: params,
        fetchingAction: SUBMITTING_NEW_BUY,
        successAction:  NEW_BUY_SUBMITTED,
        failAction:     NEW_SUBMIT_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {

        }
    })
}

export const processBuy = (transferID, params) => {
    return dispatchAction({
        url: 'orders/buy/process/' + transferID,
        params: params,
        fetchingAction: PROCESSING_ORDER,
        successAction:  ORDER_PROCESSED,
        failAction:     ORDER_PROCESS_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        }
    })
}

export const cancelBuy = (transferID, params) => {
    return dispatchAction({
        url: 'orders/buy/cancel/' + transferID,
        params: params,
        fetchingAction: CANCELING_ORDER,
        successAction:  ORDER_CANCELED,
        failAction:     ORDER_CANCEL_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {

        },
    })
}

export const processWithdraw = (transferID, params) => {
    return dispatchAction({
        url: 'withdraws/process/' + transferID,
        params: params,
        fetchingAction: PROCESSING_ORDER,
        successAction:  ORDER_PROCESSED,
        failAction:     ORDER_PROCESS_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        }
    })
}

export const cancelWithdraw = (transferID, params) => {
    return dispatchAction({
        url: 'withdraws/cancel/' + transferID,
        params: params,
        fetchingAction: CANCELING_ORDER,
        successAction:  ORDER_CANCELED,
        failAction:     ORDER_CANCEL_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            console.log('On success', data);

        },
    })
}

export const updateDelivery = (transferID, params) => {
    return updateOne({
        url: 'withdraws/update_delivery/' + transferID,
        params: params,
        fetchingAction: UPDATING_ORDER_INFO,
        successAction:  ORDER_INFO_UPDATED,
        failAction:     ORDER_INFO_UPDATE_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            console.log('On success', data);

        },
    })
}

export const bulkProcessWithdraw = (params) => {
    return dispatchAction({
        url: 'withdraws/bulk/process/',
        params: params,
        fetchingAction: PROCESSING_ORDER,
        successAction:  ORDER_PROCESSED,
        failAction:     ORDER_PROCESS_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        }
    })
}

export const bulkUpdateDeliveryTimes = (params) => {
    return dispatchAction({
        url: 'withdraws/bulk/update_delivery/',
        params: params,
        fetchingAction: UPDATING_ORDER_INFO,
        successAction:  ORDER_INFO_UPDATED,
        failAction:     ORDER_INFO_UPDATE_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        }
    })
}

export const bulkCancelWithdraw = (params) => {
    return dispatchAction({
        url: 'withdraws/bulk/cancel/',
        params: params,
        fetchingAction: CANCELING_ORDER,
        successAction:  ORDER_CANCELED,
        failAction:     ORDER_CANCEL_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {

        },
    })
}

export const bulkLockWithdraw = (params) => {
    return dispatchAction({
        url: 'withdraws/bulk/lock/',
        params: params,
        fetchingAction: LOCKING_ORDER,
        successAction:  ORDER_LOCKED,
        failAction:     ORDER_LOCK_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        },
    })
}

export const processDeposit = (transferID, params) => {
    return dispatchAction({
        url: 'orders/deposits/accept/' + transferID,
        params: params,
        fetchingAction: PROCESSING_ORDER,
        successAction:  ORDER_PROCESSED,
        failAction:     ORDER_PROCESS_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {

        }
    })
}

export const rejectDeposit = (transferID, params) => {
    return dispatchAction({
        url: 'orders/deposits/reject/' + transferID,
        params: params,
        fetchingAction: CANCELING_ORDER,
        successAction:  ORDER_CANCELED,
        failAction:     ORDER_CANCEL_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            console.log('On success', data);

        },
    })
}

export const newDeposit = (params) => {
    return dispatchAction({
        url: 'orders/deposits/new',
        params: params,
        fetchingAction: SUBMITTING_NEW_DEPOSIT,
        successAction:  NEW_DEPOSIT_SUBMITTED,
        failAction:     NEW_DEPOSIT_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {

        },
    })
}

export const lockWithdraw = (transferID, params) => {
    return dispatchAction({
        url: 'withdraws/lock/' + transferID,
        params: params,
        fetchingAction: LOCKING_ORDER,
        successAction:  ORDER_LOCKED,
        failAction:     ORDER_LOCK_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            console.log('On success', data);
            if(data.data)
                dispatch({type: ORDER_INFO_FETCHED, payload: {data: data.data}});
        },
    })
}

export const unfreezeTransaction = (transferID, params) => {
    return dispatchAction({
        url: 'orders/deposits/unfreeze/' + transferID,
        params: params,
        fetchingAction: UNFREEZING_ORDER,
        successAction:  ORDER_UNFROZEN,
        failAction:     ORDER_UNFREEZE_FAILED,
        unitName:       ORDER_MANAGEMENT,
        showError: true,
        onSuccess: function (data, dispatch) {
            // dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: false}});

        },
        onFailed: function (data, dispatch) {

        }
    })
}

export const applyListFilter = (filters, sort) => {
    return (dispatch) => {
        dispatch({type: UPDATE_ORDERS_FILTER, payload: {data: {filters: filters, sort: sort}}});
    }
};

export const switchInfoDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_ORDER_INFO_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchEditDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_ORDER_EDIT_DIALOG, payload: {state: state, data: info}});
    }
}
;
export const switchOrderVerifyDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_ORDER_DOC_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchWithdrawDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchDepositDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_DEPOSIT_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchUnfreezeDialog = (state, info) => {
    return (dispatch) => {
        dispatch({type: SWITCH_UNFREEZE_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchNewDepositDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_NEW_DEPOSIT_DIALOG, payload: {state: state, data: info}});
    }
};
export const switchReqBuyDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_REQUEST_BUY_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchReqSellDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_REQUEST_SELL_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchBuyDocDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_BUY_DOCUMENT_DIALOG, payload: {state: state, data: info}});
    }
};

export const switchSellDocDialog = (state, info = {}) => {
    return (dispatch) => {
        dispatch({type: SWITCH_SELL_DOCUMENT_DIALOG, payload: {state: state, data: info}});
    }
};





